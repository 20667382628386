import React, { useEffect, useState } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/PushTemplate";
import configuration from '../../config';
import { toast } from 'react-toastify';
import UND from 'underscore'

// For Edit Push Template
let Id = "";
function Edit({ history }) {
  const [fieldArr, setFieldArr] = useState([])
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [languages, setLanguages] = useState([])

  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const isSendOpt = [
    { id: 1, title: "Yes", value: "true" },
    { id: 2, title: "No", value: "false" },];
  const columnArr = [
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    { id: 1, title: "Code", name: "template_code", type: "TextInput", is_read_only: true },
    { id: 3, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
  ];

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'push-template/list', params: { push_template_id: Id } }).then((data) => {
      if (data.status === 200) {
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    getlanguages();
  }, []);

  function getlanguages() {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let valueArr = []
        let captionValueArr = []
        UND.each(data.payload, (lang, ind) => {
          valueArr.push({ id: ind, title: "Value[" + lang.title + "]", name: "value_" + lang.code, type: "TextInput" })
          captionValueArr.push({ id: ind, title: "Caption Value[" + lang.title + "]", name: "caption_value_" + lang.code, type: "TextInput" })
        })
        const fullCoulnmArr = columnArr.concat(valueArr, captionValueArr);
        setFieldArr(fullCoulnmArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter push title"
      flag = false
    }

    if (!fields.template_code) {
      error['template_code'] = "Please enter template code "
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }

    UND.each(languages, (lang, ind) => {
      if (!fields['value_' + lang.code]) {
        error['value_' + lang.code] = "Please enter value in " + lang.title
        flag = false
      }
      if (!fields['value_' + lang.code]) {
        error['caption_value_' + lang.code] = "Please enter caption value in " + lang.title
        flag = false
      }
    })

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      configuration.postAPI({ url: 'push-template/update', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/push-template')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  return (
    <>
      <Breadcrumb module={labels.editpushTemplate} />
      <AddEditSection
        title="Edit Push Template"
        path="/push-template"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
