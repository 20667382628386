import PropTypes from "prop-types";
import React from "react";
// import { useHistory } from "react-router-dom";

// For Adding New Record
function SaveRecord({ path, title, handleSubmit }) {
  // const history = useHistory();
  return (
    <button
      type="button"
      onClick={(e)=>handleSubmit(e)}
      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
      // onClick={() => history.push(path)}
    >
      {title}
    </button>
  );
}

SaveRecord.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
};
SaveRecord.defaultProps = {
  path: "",
  title: "",
};


export default SaveRecord;
