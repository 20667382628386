import PropTypes from "prop-types";
import React, {useState} from "react";
import Label from "./Label";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DuplicateIcon} from "@heroicons/react/outline";

// For Input Element
function TextInput({ id, name, value}) {
  const [copied, setCopied] = useState(false);
  return (
    <>
    <div className="flex">
      <div className="flex-grow">
        <input
          type="text"
          name={name}
          id={id}
          value = {value}
          readOnly={true}
          className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border-gray-300 rounded-md"
        />
      </div>
      <CopyToClipboard
        onCopy={() => setCopied(true)}
        text={value}
      >
        <span className="ml-3">
          <button
            type="button"
            className="bg-white inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            <DuplicateIcon className="-ml-2 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>Copy</span>
          </button>
        </span>
      </CopyToClipboard>
    </div>
    {copied ? <Label title='Copy' fieldError='Copied.' /> : null}
  </>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: "",
  name: "",
};

export default TextInput;
