import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../constants/Role";
import configuration from '../../config';
import { toast } from 'react-toastify';

// For Edit State
let Id = "";
function Edit({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const fieldArr = [
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    {
      id: 1,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
    { id: 2, title: "Description", name: "description", type: "TextArea" },
  ];
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter role title"
      flag = false
    }

    if (!fields.description) {
      error['description'] = "Please enter description"
      flag = false
    }
    
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'role/update', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/role')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'role/list', params:{role_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editRole} />
      <AddEditSection title="Edit Role" path="/role" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
