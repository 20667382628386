import PropTypes from "prop-types";
import React, {useState} from "react";
import Viewer from 'react-viewer';

// For Input Element
function TextInput({ id, name, handleChange, cancleImage, value}) {
  const [visiblePic, setVisiblePic] = useState(false);
  return (
    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      {(value && value !== '')?
        <div className="space-y-1">
          <img name={name} id={id} src={value} width="100" height="100"/>
          <button 
            type="button" 
            onClick={(e)=>cancleImage(name, e)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          > Remove</button>
          <button 
            type="button" 
            onClick={()=> setVisiblePic(true)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
          > View</button>
          <Viewer visible={visiblePic} onClose={()=> setVisiblePic(false) } images={[{src: value, alt: name}]} />
        </div>
        :<div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor={name}
              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
            >
              <span>Upload a file</span>
              <input
                type= "file"
                id={name} name={name}
                onChange={(e)=>handleChange(name, e)}
                accept="image/*"
                autoComplete="given-name"
                className="sr-only"
              />
            </label>
            <p className="pl-1 text_theme">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
        </div>
      }
    </div>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

TextInput.defaultProps = {
  id: "",
  name: "",
};

export default TextInput;
