import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, Fragment } from "react";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react'
import country_code from "../../country_code";

function View({ history }) {
  const [data, setData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [open, setOpen] = useState(false)
  const [isDirect, setIsDirect] = useState(true)
  const [error, setError] = useState({})
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    mobile: "",
    mobile_country_code: "+1",
    type: "web"
  })
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    if ((!open && isDirect)) {
      getDatas();
      increasePopCount();
    }
  }, []);


  useEffect(() => {
    getUser()
  }, [])

  let length1 = history.location.pathname.split('/').length;
  length1 = (length1 - 1)
  let userId = history.location.pathname.split('/')[length1];

  function increasePopCount() {
    configuration.getAPI({ url: 'user/get-web-view-url', params: { user_id: userId } }).then((data) => {
      if (data.status === 200) {
        console.log("");
      } else if (data.status === 404) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function getDatas() {
    configuration
      .getAPI({ url: "user/web-view-url", params: { user_id: userId } })
      .then((data) => {
        if (data.status === 200) {
          configuration.getAPI({ url: "user/check-direct-service", params: { user_id: userId } })
            .then((dataService) => {
              if (dataService.status === 200) {
                if (dataService.payload) {
                  window.location.href = dataService.payload
                  return;
                } else {
                  setData(data.payload.userInfo);
                  setServiceData(data.payload.userServices);
                }
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function valiDation() {
    let errors = {}
    let formIsValid = true;

    if (!(userData.email)) {
      errors["email"] = `${data.active_lang_code == 'ES' ? "Por favor introduzca su correo electrónico" : "Please Enter Your Email"}`;
      formIsValid = false
    }
    if (userData.email) {
      let lastAtPos = userData.email.lastIndexOf('@');
      let lastDotPos = userData.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && userData.email.indexOf('@@') === -1 && lastDotPos > 2 && (userData.email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = `${data.active_lang_code == 'ES' ? "Por favor ingrese una dirección de correo electrónico válida." : "Please Enter Valid Email Address."}`;
      }
    }
    if (!(userData.name)) {
      formIsValid = false
      errors["name"] = `${data.active_lang_code == 'ES' ? "Por favor, escriba su nombre" : "Please Enter Your Name"}`;
    }
    if (!(userData.mobile)) {
      formIsValid = false
      errors["mobile"] = `${data.active_lang_code == 'ES' ? "Por favor, introduzca su número de teléfono" : "Please Enter Your Phone Number"}`;
    }
    if (userData.mobile) {
      if (userData.mobile.length < 7 || userData.mobile.length > 10) {
        formIsValid = false;
        errors["mobile"] = `${data.active_lang_code == 'ES' ? "Ingrese Móvil b/n de 7 a 10 dígitos." : "Please Enter Mobile b/w 7 to 10 digit."}`;
      }
    }
    setError(errors)
    return formIsValid
  }

  function handleCount(webLink) {
    let user_id = {
      user_id: userId
    };
    configuration.postAPI({ url: 'user/increase-link-taps', params: user_id }).then((data) => {
      if (data.status === 200) {
        window.location.href = webLink;
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  const handleConnect = () => {
    if (valiDation()) {
      configuration.postConnectionAPI({ url: 'connection/connection-create', params: { ...userData, user_id: userId } }).then((data) => {
        if (data.status === 200) {
          setOpen(false)
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  function getUser() {
    configuration
      .getUserAPI({ url: "user/get-profile", params: { user_id: userId, type: "web" } })
      .then((data) => {
        console.log({ data })
        setOpen(data?.payload?.is_lead_capture)
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }


  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={() => setOpen(true)}>
          <div className="flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 mb-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg text-center leading-6 font-medium text-gray-900">
                        {data && data.active_lang_code == 'ES' ? "Conectate conmigo" : "Connect with me"}
                      </Dialog.Title>
                      <div className="mt-5 w-full">
                        <div>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder={data && data.active_lang_code == 'ES' ? "Nombre" : "Name"}
                              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                            />
                            <p className="mt-2 text-sm text-red-600" id="email-error">
                              {error.name}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="mt-2 w-full">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder={data && data.active_lang_code == 'ES' ? "Correo electrónico" : "Email"}
                              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                            />
                            <p className="mt-2 text-sm text-red-600" id="email-error">
                              {error.email}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="relative mt-2 rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                              <label htmlFor="country" className="sr-only">
                                Country
                              </label>
                              <select
                                id="country"
                                name="country"
                                autoComplete="country"
                                className="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                onChange={(e) => setUserData({ ...userData, mobile_country_code: e.target.value })}
                              >
                                {country_code?.countries?.map((data) => {
                                  return <option value={data.dial_code}>{data.code}</option>

                                })}
                              </select>
                            </div>
                            <input
                              type="number"
                              name="phone-number"
                              id="phone-number"
                              className="block w-full rounded-md border-gray-300 pl-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder={data && data.active_lang_code == 'ES' ? "Número de teléfono" : "Phone Number"}
                              onChange={(e) => setUserData({ ...userData, mobile: e.target.value })}

                            />
                          </div>
                          <p className="mt-2 text-sm text-red-600" id="email-error">
                            {error.mobile}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 bg-black px-4 py-5 sm:px-6 flex items-center justify-center">
                  <button
                    type="button"
                    className="w-full px-20 inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleConnect}
                  >
                    {data && data.active_lang_code == 'ES' ? "Conectar" : "Connect"}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {(!open && isDirect) && <main>

        <div
          style={{ textAlign: 'center', backgroundImage: `url(${(data.profile_type === 'personal') ? (data.personal_data) ? data.personal_data.cover_image_url : '../image/placeholder.jpg' : (data.business_data) ? (data.business_data.cover_image_url) : '../image/placeholder.jpg'})`, position: 'relative', backgroundSize: 'cover', height: '200px', }}>
          <div style={{ paddingTop: '150px' }}>
            <img style={{ width: '100px', height: '100px', margin: '0 auto', borderRadius: '50%', border: '4px solid #ffffff' }}
              alt="" src={(data.profile_type === 'personal') ? (data.personal_data) ? data.personal_data.profile_image_url : '../image/favicon.ico' : (data.business_data) ? (data.business_data.profile_image_url) : '../image/favicon.ico'} />
            <h2 style={{ fontWeight: '500', fontSize: '20px', textAlign: 'center', padding: '0', margin: '0' }}>{(data.profile_type === 'personal') ? (data.personal_data) ? data.personal_data.name : (data.name) : (data.business_data) ? (data.business_data.name) : (data.name)}
            </h2>
            <h2 style={{ fontWeight: '400', fontSize: '14px', textAlign: 'center', padding: '0', margin: '0' }}>{(data.profile_type === 'personal') ? (data.personal_data) ? data.personal_data.bio : (data.bio) : (data.business_data) ? (data.business_data.bio) : (data.bio)}
            </h2>
            <h2 style={{ fontWeight: '400', fontSize: '14px', textAlign: 'center', padding: '0', margin: '0' }}>{(data.profile_type === 'personal') ? (data.personal_data) ? data.personal_data.location : (data.location) : (data.business_data) ? (data.business_data.location) : (data.location)}
            </h2>
          </div>
        </div>
        <div style={{ background: '#ffffff', padding: '130px 20px 30px' }}>
          <p style={{ fontSize: '18px', textAlign: 'center', fontWeight: '500' }}>{data.active_lang_code === 'ES' ? "Bienvenida a mi tarjeta de visita digital" : "Welcome to my digital Business Card"}</p>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <a style={{ width: '80%', maxWidth: '50%', margin: '0 auto', background: '#000000', borderRadius: '60px', padding: '13px 0', display: 'inline-block', color: '#ffffff', textDecoration: 'none', textAlign: 'center', }}
              href={`https://assets.plaptech.com/vcfFiles/${data.user_id}.vcf`}>{data.active_lang_code === 'ES' ? "Agregar a contacto" : "Add to Contact"}</a>
            {/* href={`http://assets.plap.crypticpoint.com/vcfFiles/${data.user_id}.vcf`}>Add to Contact</a> */}
          </div>
          <div style={{ padding: '30px 0 0 0', width: '100%', margin: '0 auto', gridTemplateColumns: 'repeat(3,minmax(0,1fr))', columnGap: '1.5rem', rowGap: '1.5rem', display: 'grid', justifyItems: 'center' }}>
            {
              (serviceData.length > 0) ?
                serviceData.map((service) => {
                  return (<a onClick={() => handleCount(service.link)}><img style={{ width: '70px', }} alt="" src={service.icon_url} /></a>)
                })
                : data.active_lang_code === 'ES' ? "La usuario no ha añadido ningún enlace." : "User has not added any links"
            }
          </div>
        </div>
      </main>}
    </>


  );
}

View.propTypes = {
  history: PropTypes.func,
};

View.defaultProps = {
  history: () => {
  },
};

export default View;
