import PropTypes from "prop-types";
import React from "react";

// Form Label
function Label({ title }) {
  return (
    <label
      htmlFor="push-everything"
      className="ml-3 block text-base font-medium text-gray-700 text_theme"
    >
      {title}
    </label>
  );
}

Label.propTypes = {
  title: PropTypes.string,
};

Label.defaultProps = {
  title: "",
};

export default Label;
