import { DocumentTextIcon, UserIcon, UserGroupIcon, ChartBarIcon, ServerIcon, DocumentReportIcon, ChatAlt2Icon, CurrencyDollarIcon, LocationMarkerIcon, CogIcon, ShieldCheckIcon, CreditCardIcon, TruckIcon, ArchiveIcon, GiftIcon, AcademicCapIcon, UsersIcon, DeviceMobileIcon, MailIcon } from "@heroicons/react/outline";
import React, { useState, Fragment, useEffect } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import _ from "underscore";
import labels from "../../constants/User";
import Dropdown from "../../components/Form/Dropdown";
import Label from "../../components/Form/Label";
import TextInput from "../../components/Form/TextInput";
import FileInput from "../../components/Form/FileInput";
import { Link, useParams } from "react-router-dom";
import BtnRedirect from "../../components/Form/Button/BtnRedirect";
import BtnSaveRecord from "../../components/Form/Button/BtnSaveRecord";
import DataTable from "../../components/MDataTable";
import { Menu, Transition } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/solid'

import configuration from "../../config";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from 'moment';



// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



// List Settings
function View() {
  const { user_id } = useParams()

  const [subNavigation, setSubNavigation] = useState([
    { name: "Basic Information ", href: "#", icon: DocumentReportIcon, current: true },
    { name: "Services ", href: "#", icon: ServerIcon, current: false },
    { name: "Connection ", href: "#", icon: UserIcon, current: false },
    { name: "Groups", href: "#", icon: UserGroupIcon, current: false },
    { name: "Insights", href: "#", icon: ChartBarIcon, current: false },
    { name: "Change Password ", href: "#", icon: ServerIcon, current: false },

  ]);

  
  const fieldArr1 = [
    { id: 0, title: "Password", name: "password", type: "TextInput", is_password: true },
    { id: 1, title: "Confirm Password", name: "cpassword", type: "TextInput", is_password: true },
  ];
  const [passFields, setPassFields] = useState({});
  const [passErrors, setPassErrors] = useState({
    password: '',
    cpassword: ''
  });
  const [fieldArr2, setFieldArr2] = useState([]);
  const [language, setLanguage] = useState([])
  const [data, setData] = useState([]);
  const [userdata, setUserData] = useState([]);
  const [connections, setConnection] = useState([]);
  const [groups, setGroup] = useState([]);
  const [insights, setInsights] = useState([]);
  const [personalService, setPersonalService] = useState([]);
  const [businessService, setBusinessService] = useState([]);



  const [tab, setTab] = useState(0)
  function setTabMenu(index) {
    setTab(index)
    const navigate = subNavigation;
    _.each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }

  useEffect(() => {
    
    getDatas();
    getUsers();
    getConnections();
    getGroups();
    getInsights();
    getUserService();
  }, []);

  function getInsights() {
    configuration
      .getAPI({ url: "dashboard/get-insights-details", params: { user_id: user_id } })
      .then((data) => {
        if (data.status === 200) {
          setInsights(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getUserService() {
    configuration
      .getAPI({ url: "user-detail/user-service-list", params: { user_id: user_id } })
      .then((data) => {
        if (data.status === 200) {
          setPersonalService(data.payload.personalService);
          setBusinessService(data.payload.businessService)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getGroups() {
    configuration
      .getAPI({ url: "user-detail/group-list", params: { user_id: user_id } })
      .then((data) => {
        if (data.status === 200) {
          setGroup(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleChangePass(field, e) {
    setPassFields({ ...passFields, [field]: e.target.value })
  }

  function validationPass() {
    let flag = true;
    let passErrors = {}
    const passRegex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!passRegex.test(passFields.password)) {
      passErrors['password'] = "The password must be a minimum of 8 characters, a maximum of 20 characters, at least one uppercase character, at least one lowercase character, at least one digit, at least one special character";
      flag = false
    }

    if (!passFields.cpassword) {
      passErrors['cpassword'] = "Please enter confirm password"
      flag = false
    }

    if (passFields.cpassword !== passFields.password) {
      passErrors['cpassword'] = "Password and confirm password doesn't match."
      flag = false
    }
    setPassErrors({ ...passErrors })
    return flag;
  }
  const handleChangePassword = () => {
    if (validationPass()) {
      var url = window.location.href;
      let Id = url.substring(url.lastIndexOf("/") + 1);
      configuration.postAPI({ url: 'user/change-password', params: { ...passFields, user_id: Id } }).then((data) => {
        // console.log(data)
        if (data.status === 200) {
          // history.push('/user')
          setPassFields({ password: '', cpassword: '' })
          return toast.success('Password changed successfully')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  function getConnections() {
    configuration
      .getAPI({ url: "user-detail/connection-list", params: { user_id: user_id } })
      .then((data) => {
        if (data.status === 200) {
          setConnection(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getDatas() {
    configuration
      .getAPI({ url: "service/list", params: { user_id: user_id } })
      .then((data) => {
        if (data.status === 200) {
          setData(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getUsers() {
    configuration.getAPI({ url: 'user/list', params: { user_id: user_id } }).then((data) => {
      if (data.status === 200) {
        console.log('data.payload')
        console.log(data.payload)
        setUserData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  const profile = {
    fields: {
      // 'First Name': 'ricardo',
      // 'Last Name': 'cooper',
      'Name': userdata.EN,
      'Email ': userdata.email,
      'Profile Type': userdata.profile_type,
      'Mobile': userdata.mobile,
      // 'Gender': userdata.gender,
      // 'Country ': 'India',
      // 'State': 'Gujarat',
      // 'City': 'Ahmedabad',
      // 'Date of Birth': 'June 8, 1990',
      // 'Reg Date': '07/01/2022, 04:16',
      'Status': userdata.status,
      // 'App Version': '1.5',
      'Device Name': userdata.device_name,
      'Device Type': userdata.device_type,
      'Device Token': userdata.device_token,
    },
  }

  return (
    <>
      <Breadcrumb module={labels.Profile} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

                  <div className="w-full md:space-x-5 mb-6 mt-4">
                    <div className="flex items-center space-x-5 justify-between">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <div className="relative">
                            <img
                              className="h-14 w-14 rounded-md mt-1"
                              // src={userdata.photo ? userdata.photo : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYsTbWjuQm15MFQBNxXkihFDoccB-HhZLA-A&usqp=CAU"}
                              src={userdata.photo ? userdata.photo : (userdata.personal_data)? userdata.personal_data.profile_image_url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYsTbWjuQm15MFQBNxXkihFDoccB-HhZLA-A&usqp=CAU'}
                              // src={userdata.photo}
                              alt=""
                            />
                            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
                          </div>
                        </div>
                        <div className="pl-5">
                          <h1 className="text-xl font-bold text-gray-900">{userdata.EN}</h1>
                          {/* <h1 className="text-xl font-bold text-gray-900">Ricardo Cooper</h1> */}
                          <p>{userdata.email}</p>
                          {/* <p>Paplme.co/f3fwdn22</p> */}
                        </div>
                      </div>
                      {/* <div className="flex justify-between gap-10">
                        <div>
                          <h3 className="mb-3">Personal Profile</h3>
                          <img className="w-24" src="../image/qrcode.png" alt="" />
                        </div>
                        <div>
                          <h3 className="mb-3">Business Profile</h3>
                          <img className="w-24" src="../image/qrcode.png" alt="" />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="">
                    <main className="relative">
                      <div className="mx-auto">
                        <div className="">
                          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0">
                            <aside className="py-6 lg:col-span-2">
                              <nav className="space-y-1">
                                {subNavigation.map((item, index) => (
                                  <Link
                                    to="#"
                                    key={item.name}
                                    onClick={() => setTabMenu(index)}
                                    onKeyDown={() => setTabMenu(index)}
                                    className={classNames(
                                      item.current
                                        ? "bg-gray-200 text-gray-900 text-teal-700 hover:bg-teal-50 hover:text-teal-700 rounded-md"
                                        : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                      "group px-3 py-2 flex items-center text-base"
                                    )}
                                    aria-current={item.current ? "page" : null}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-teal-500 group-hover:text-teal-500"
                                          : "text-gray-400 group-hover:text-gray-500",
                                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="truncate">
                                      {item.name}
                                    </span>
                                  </Link>
                                ))}
                              </nav>
                            </aside>
                            {tab === 0 ? <div className="md:pl-8 sm:py-7 col-span-10">
                              <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                  <div className="px-4 py-5 sm:px-6">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                      Basic Information
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                  </div>
                                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    < div className="">
                                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        {Object.keys(profile.fields).map((field) => (
                                          <div key={field} className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">{field}</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{profile.fields[field]}</dd>
                                          </div>
                                        ))}
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div> : ''}

                            {tab === 1 ? <div className="md:pl-8 sm:py-7 col-span-10">
                              <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                  <div className="px-4 py-5 sm:px-6 border-b">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                      Personal Services
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                  </div>
                                  <div className="services px-6">
                                    <dl className="divide-y divide-gray-200">
                                      {data.personal == 'true' ? data.personal : ""}
                                      {personalService.map((service, key) => {
                                        return (
                                          <>
                                            {/* {service.personal && */}
                                              <div className="py-4 sm:py-5 flex justify-between items-center">
                                                <dt className="flex font-medium items-center">
                                                  <img src={service.icon_url} alt=""  width="50px"/>
                                                  <span className="text-black font-bold text-xl pl-5">{service.title}</span>
                                                </dt>
                                                {/* <dd className="mt-1 text-gray-900">
                                                  <button
                                                    type="button"
                                                    className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                                                  >
                                                    {service.labels.map((label1) => {
                                                      return <div>
                                                        {label1} <br />
                                                      </div>
                                                    })}
                                                  </button>
                                                </dd> */}
                                              </div>
                                              
                                          </>
                                        )

                                      })}

                                    </dl>
                                  </div>
                                </div>
                                <div className="bg-white shadow sm:rounded-lg mt-5">
                                  <div className="px-4 py-5 sm:px-6 border-b ">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                      Business Services
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                  </div>


                                  <div className="services px-6">
                                    <dl className="divide-y divide-gray-200">
                                      {businessService.map((service, key) => {
                                        return (
                                          <>
                                              <div className="py-4 sm:py-5 flex justify-between items-center">
                                                <dt className="flex font-medium items-center">
                                                  <img src={service.icon_url} alt="" width="50px"/>
                                                  <span className="text-black font-bold text-xl pl-5">{service.title}</span>
                                                </dt>
                                                {/* <dd className="mt-1 text-gray-900">
                                                  <button
                                                    type="button"
                                                    className="bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                                                  >
                                                    {service.labels.map((label) => {
                                                      return <div>
                                                        {label} <br />
                                                      </div>
                                                    })}

                                                  </button>
                                                </dd> */}
                                              </div>
                                          </>
                                        )

                                      })}

                                    </dl>
                                  </div>
                                </div>
                              </section>
                            </div> : ''}

                            {tab === 2 ? <div className="md:pl-8 sm:py-7 col-span-10">
                              <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                  <div className="px-4 py-5 sm:px-6 border-b">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                      Connection
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                  </div>

                                  <div className="connection px-6 py-6 grid grid-cols-2 gap-5">
                                    {
                                      connections.map((connection) => {
                                        return (
                                          <>
                                            <div className="flex border-b pb-5">

                                              <div className="mr-4 flex-shrink-0 self-center">
                                                <img className="w-16 rounded-full" src={connection.profile_image ? connection.profile_image_url : "/image/no_image.png"} alt="" />
                                              </div>
                                              <div>
                                                <h4 className="text-base font-bold">{connection.name}</h4>
                                                <p className="text-sm text-gray-600">{connection.job_title}</p>
                                                <p className="text-sm">{moment(connection.created_at).format('dddd, DD MMM')}</p>
                                              </div>
                                            </div>

                                          </>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </section>
                            </div> : ''}

                            {tab === 3 ? <div className="md:pl-8 sm:py-7 col-span-10">
                              <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                  <div className="px-4 py-5 sm:px-6 border-b">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                      Groups
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                  </div>

                                  <div className="connection px-6 py-6 grid grid-cols-2 gap-5">
                                    {
                                      groups.map((group)=>{
                                        return(
                                          <div className="border rounded-md px-4 py-4">
                                            <div className="flex justify-between">
                                              <h3 className="mb-4 font-bold text-lg">{group.group_name}</h3>
                                              <p className="font-bold">{group.Member.length}</p>
                                            </div>
                                            {
                                              (group.Member.length > 0)?
                                              group.Member.map((member)=>{
                                                return(
                                                  <>
                                                  <img
                                                      className="mr-3 inline-block h-12 w-12 rounded-full"
                                                      src={(member.profile_image_url) ? member.profile_image_url : "/image/no_image.png"}
                                                      alt=""
                                                    />
                                                  </>
                                                )
                                              })
                                              :
                                              <img className="mr-3 inline-block h-12 w-12 rounded-full" src="/image/no_image.png" alt=""/>
                                            }
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              </section>
                            </div> : ''}
                            {tab === 4 ? <div className="md:pl-8 sm:py-7 col-span-10">
                              <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                  <div className="px-4 py-5 sm:px-6 border-b">
                                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                      Insights
                                    </h2>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500 text_theme">This information will be displayed publicly so be careful what you share.</p>
                                  </div>

                                  <div className="connection px-6 py-6 grid grid-cols-2 md:grid-cols-4 gap-5">
                                    <div className="border rounded-md px-4 py-4">
                                      <div className="text-center">
                                        <h3 className="mb-4 font-bold text-lg">Pops</h3>
                                        <p className="font-bold">{insights.pops_count}</p>
                                      </div>
                                    </div>
                                    <div className="border rounded-md px-4 py-4">
                                      <div className="text-center">
                                        <h3 className="mb-4 font-bold text-lg">Link Taps</h3>
                                        <p className="font-bold">{insights.link_taps_count}</p>
                                      </div>
                                    </div>
                                    <div className="border rounded-md px-4 py-4">
                                      <div className="text-center">
                                        <h3 className="mb-4 font-bold text-lg">New Connections</h3>
                                        <p className="font-bold">{insights.connection_count}</p>
                                      </div>
                                    </div>
                                    <div className="border rounded-md px-4 py-4">
                                      <div className="text-center">
                                        <h3 className="mb-4 font-bold text-lg">Tap Though Rate</h3>
                                        <p className="font-bold">{`${insights.tap_through_rate}%`}</p>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </section>
                            </div> : ''}

                            {tab === 5 ? <div className="md:pl-8 sm:py-7 col-span-10">
                              <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                      {fieldArr1.map((field) => {
                                        return (
                                          <div className="sm:col-span-3" key={field.id}>
                                            <Label title={field.title} />
                                            <div className="mt-1">
                                              {field.type === "TextInput" ? (
                                                <TextInput
                                                  id={field.name}
                                                  name={field.name}
                                                  handleChange={handleChangePass}
                                                  value={passFields[field.name]}
                                                  is_number={field.is_number || false}
                                                  is_password={field.is_password || false}
                                                />
                                              ) : null}
                                            </div>
                                            {passErrors[field.name] ? <Label title={field.title} fieldError={passErrors[field.name]} /> : null}
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <div className="pt-5 border_color_theme">
                                      <div className="flex justify-end">
                                        <BtnRedirect title="Cancel" path="/user" />
                                        <BtnSaveRecord handleSubmit={handleChangePassword} title="Submit" path="/user" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div> : ''}

                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section >
        </div >
      </div >
    </>
  );
}

export default View;
