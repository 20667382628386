import React, { useState } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import ColumnHeader from "../../components/AccessRight/ColumnHeader";
import ColumnRow from "../../components/AccessRight/ColumnRow";
import Label from "../../components/AccessRight/Label";
import labels from "../../constants/AccessRight";

// List Access Rights
function View() {
  const people = [
    { id:0, name: "USERS", title: "Regional Paradigm Technician", role: "Admin" },
    {
      id:1, 
      name: "EmailTemplate",
      title: "Product Directives Officer",
      role: "Owner",
    },
    {
      id:2, 
      name: "PushNotification",
      title: "Product Directives Officer",
      role: "Admin",
    },
    { id:3, name: "CMS", title: "Product Directives Officer", role: "Admin" },
    { id:4, name: "Help", title: "Product Directives Officer", role: "Owner" },
    {
      id:5, 
      name: "HelpCategories",
      title: "Product Directives Officer",
      role: "Owner",
    },
  ];

  const columnHeaders = [
    "View",
    "Add",
    "Edit",
    "Active",
    "In Active",
    "Archive",
    "Delete",
  ];
  const [type, setType] = useState("role");
  return (
    <div className="min-h-screen bg-gray-100 page_bg">
      <Breadcrumb module={labels.accessRight} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Permissions
                      </h2>
                    </div>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:border-b border-bottom-theme">
                          <div className="sm:p-6">
                            <div className="gap-y-6 gap-x-4 sm:grid-cols-12">
                              <div className="mt-1 text-right">
                                <button
                                  type="submit"
                                  className="mr-3 mb-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                            <div className="gap-y-6 gap-x-4 sm:grid-cols-12">
                              <div className="mt-4 space-y-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
                                <div className="flex items-center m-0">
                                  <input
                                    id="push-everything"
                                    name="push-notifications"
                                    type="radio"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    value={type}
                                    defaultChecked
                                    onChange={() => setType("role")}
                                  />
                                  <Label title="By Role" />
                                </div>
                                <div className="flex items-center m-0">
                                  <input
                                    id="push-email"
                                    name="push-notifications"
                                    type="radio"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    value={type}
                                    onChange={() => setType("user")}
                                  />
                                  <Label title="By User" />
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div
                                className="sm:col-span-3"
                                style={{
                                  display: type === "role" ? "inline" : "none",
                                }}
                              >
                                <Label title="Role" />
                                <div className="mt-1">
                                  <select
                                    id="country"
                                    name="country"
                                    autoComplete="country"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
                                  >
                                    <option>Super Administrator</option>
                                    <option>Staff</option>
                                    <option>HR</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="sm:col-span-3"
                                style={{
                                  display: type === "user" ? "inline" : "none",
                                }}
                              >
                                <Label title="User" />
                                <div className="mt-1">
                                  <select
                                    id="country"
                                    name="country"
                                    autoComplete="country"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
                                  >
                                    <option>Pratik Balochiya</option>
                                    <option>Kevin Kalariya</option>
                                    <option>Jayendra Kyada</option>
                                    <option>Jigar Prajapati</option>
                                    <option>Jaikit Chaudhary</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg border-bottom-theme">
                              <table className="min-w-full divide-y divide-gray-200" style={{borderCollapse:"inherit" }}>
                                <thead className="bg-gray-50 permissions_data_heading">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="relative px-6 py-3 text-left text_theme"
                                    >
                                      Module Name
                                    </th>
                                    {columnHeaders.map((header) => {
                                      return (
                                        <ColumnHeader
                                          title={header}
                                          key={header}
                                        />
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {people.map((person, personIdx) => (
                                    <tr
                                      key={person.id}
                                      className={
                                        personIdx % 2 === 0
                                          ? "bg-white box_bg"
                                          : "bg-gray-50 box_bg"
                                      }
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text_theme">
                                        {person.name}
                                      </td>
                                      {columnHeaders.map(
                                        (header) => {
                                          return <ColumnRow key={header} />;
                                        }
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default View;
