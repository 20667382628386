import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import labels from "../../constants/Dashboard";
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import ListSection from "../../components/ListSection";
import { ArchiveIcon, DocumentRemoveIcon, UserIcon, MailIcon, AnnotationIcon, MailOpenIcon, InboxInIcon, SpeakerphoneIcon, OfficeBuildingIcon, CheckCircleIcon, UserCircleIcon } from '@heroicons/react/outline'
import { Link } from "react-router-dom";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { pluck } from "underscore";
import DataTable from "../../components/MDataTable";
import {
  DocumentDuplicateIcon,
  CogIcon,
  UsersIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
} from 'chart.js';



import { Line, Bar } from "react-chartjs-2";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

// For Dashboard

function View({ location }) {
  const allYears = (startYear) => {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    years = years.reverse();
    return years;
  };

  const [show, setShow] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [userActiveData, setUserActiveData] = useState([]);

  const [year, setYear] = useState(new Date().getFullYear());

  const [dashboradData, setDashboradData] = useState([]);
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "number of users ",
        data: dashboradData.totalusermonthwise,
        backgroundColor: [
          "rgba(144, 53, 255)",
          "rgba(133, 105, 241)",
          "rgba(54, 113, 255)",
          "rgba(44, 56, 132)",
          "rgba(83, 175, 238)",
          "rgba(164, 101, 241)",
        ],
      },
    ],
  };
  const data2 = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "number of active users ",
        data: dashboradData.totalusermonthwiseActive,
        backgroundColor: [
          "rgba(144, 53, 255)",
          "rgba(133, 105, 241)",
          "rgba(54, 113, 255)",
          "rgba(44, 56, 132)",
          "rgba(83, 175, 238)",
          "rgba(164, 101, 241)",
        ],
      },
    ],
  };
  useEffect(() => {
    setYearList(allYears(2000));
    if (location.state && location.state.is_added) {
      setShow(true);
      setTimeout(() => setShow(false), 3000);
    }
    getDatas();
    getUsers();
  }, [location.state]);

  function getDatas() {
    configuration
      .getAPI({ url: "dashboard/all-data", params: {} })
      .then((data) => {
        if (data.status === 200) {
          setDashboradData(data.payload);
        }
      })
      .catch((error) => {
      });
  }
  function getUsers() {
    configuration.getAPI({ url: 'user/list', params: {} }).then((data) => {
      if (data.status === 200) {
        setUserData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }



  // const changeYearData = (e) => {
  //   setYear(e.target.value);
  //   getUsers(e.target.value);


  // };
  const category = [

    { id: 1, name: 'Active ', stat: dashboradData.totalActiveCategory || 0, icon: CheckCircleIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Inactive ', stat: dashboradData.totalInActiveCategory || 0, icon: DocumentRemoveIcon, change: '5', changeType: 'decrease' },
    { id: 2, name: 'Archive ', stat: dashboradData.totalArchiveCategory || 0, icon: ArchiveIcon, change: '5', changeType: 'decrease' },
  ]
  const user = [
    { id: 1, name: 'Active ', stat: dashboradData.totalActiveUsers || 0, icon: CheckCircleIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Inactive ', stat: dashboradData.totalInActiveUsers || 0, icon: DocumentRemoveIcon, change: '5', changeType: 'decrease' },
    { id: 2, name: 'Archive ', stat: dashboradData.totalArchiveUsers || 0, icon: ArchiveIcon, change: '5', changeType: 'decrease' },
  ]
  const services = [
    { id: 1, name: 'Active ', stat: dashboradData.totalActiveService || 0, icon: CheckCircleIcon, change: '122', changeType: 'increase' },
    { id: 2, name: 'Inactive ', stat: dashboradData.totalInActiveService || 0, icon: DocumentRemoveIcon, change: '5', changeType: 'decrease' },
    { id: 2, name: 'Archive ', stat: dashboradData.totalArchiveService || 0, icon: ArchiveIcon, change: '5', changeType: 'decrease' },
  ]


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const options = {
    scales: {
      // yAxes: [
      //   {
      //     ticks: {
      //       beginAtZero: true,
      //     },
      //   },
      // ],
    },
  };



  return (
    <>
      {/* <Breadcrumb module={labels.dashboard} /> */}


      <div className="w-full mt-8">
        <div className="bg-white overflow-hidden shadow rounded-lg mt-2">
          <div className="p-5">
            <div className="w-full">


              <div className="w-full grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-8">
                <div className="mt-3">
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mt-3">
                    Total Number of Users
                  </h2>
                  <div className="w-full border border-gray-200 border-solid mt-3">
                    <Bar data={data} options={options} />
                  </div>
                </div>
                <div className="mt-3">
                  <h2 className="text-lg leading-6 font-medium text-gray-900 mt-3">
                    Total Number of Active Users
                  </h2>
                  <div className="w-full border border-gray-200 border-solid mt-3">
                    <Bar data={data2} options={options} />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="px-6 py-6 gap-5 grid grid-flow-row-dense md:grid-cols-3 grid-rows-1"> */}
      {/* <dl className="shadow-md px-4 py-4 bg-white rounded-md md:col-span-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Total Services  </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {services.map((item) => (
              <div
                key={item.id}
                className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-600 truncate">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                </dd>
              </div>
            ))}
          </dl>
        </dl> */}
      {/* </div> */}
      {/* <div className="px-6 py-6 gap-5 grid grid-flow-row-dense md:grid-cols-3 grid-rows-1"> */}
      <dl className="shadow-md px-4 py-4 bg-white rounded-md md:col-span-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Total Category  </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {category.map((item) => (
            <div
              key={item.id}
              className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden"
            >
              <dt>
                <div className="absolute bg-indigo-600 rounded-md p-3">
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 text-sm font-medium text-gray-600 truncate">{item.name}</p>
              </dt>
              <dd className="ml-16 flex items-baseline">
                <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              </dd>
            </div>
          ))}
        </dl>
      </dl>
      {/* </div> */}

      <dl className="shadow-md px-4 py-4 bg-white rounded-md md:col-span-2">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Total Services  </h3>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {services.map((item) => (
              <div
                key={item.id}
                className="relative bg-gray-50 pt-5 px-6 pb-6  shadow rounded-lg overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-indigo-600 rounded-md p-3">
                    <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-sm font-medium text-gray-600 truncate">{item.name}</p>
                </dt>
                <dd className="ml-16 flex items-baseline">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                </dd>
              </div>
            ))}
          </dl>
      </dl>

      
{/* 
      <dl className="shadow-md px-4 py-4 bg-white rounded-md md:col-span-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Total Personal Profile  </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <p className="ml-16 text-sm font-medium text-gray-600 truncate">{dashboradData.totalPersonal}</p>
        </dl>



      </dl>

      <dl className="shadow-md px-4 py-4 bg-white rounded-md md:col-span-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Total Business Profile  </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <p className="ml-16 text-sm font-medium text-gray-600 truncate">{dashboradData.totalBusiness}</p>
        </dl>
      </dl> */}

      
      {/* <dl className="shadow-md px-4 py-4 bg-white rounded-md md:col-span-2">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Total Links  </h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <p className="ml-16 text-sm font-medium text-gray-600 truncate">{dashboradData.totalLinks}</p>
        </dl>
      </dl> */}


    </>
  );
}

export default View;
