import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

// Multi Select Dropdown
function MultiDropdown({ name, options, handleChange, value }) {
  return (
    <div className="dark_drop">
    <Select
      className="shadow-sm text-base"
      options={options}
      value={value}
      onChange={(e) => handleChange(name, e)}
      labelledBy="Select"
      isMulti
    /></div>
  );
}

MultiDropdown.propTypes = {
  options: PropTypes.instanceOf(Array),
  handleChange: PropTypes.func,
};

MultiDropdown.defaultProps = {
  options: [],
  handleChange: () => {},
};

export default MultiDropdown;
