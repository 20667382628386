import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Category";
import _ from 'underscore'
import { reactLocalStorage } from 'reactjs-localstorage';

// For Edit Language
let Id = "";

function Edit({ history }) {
  const statusOpt = [
    
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];

  const [fieldArr, setFieldArr] = useState([
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ]);
  const [fields, setFields] = useState({});
  const [fieldArr1, setFieldArr1] = useState([]);
  const [errors, setErrors] = useState({
    title: "",
    status: "",
  });
  const [language, setLanguage] = useState([])


  function handleValidation() {
    let flag = true;
    let error = {}
    language.forEach((single) => {
      if (!fields[single.code]) {
        error[single.code] = "Please enter category title in " + single.title
        flag = false
      }
    })

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "category/list", params: { category_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });

    let apiTokenData = reactLocalStorage.get('token');
    let user_id = reactLocalStorage.get('user_id');
    console.log(apiTokenData, user_id)
    let value = {
      status: 'active'
    }

    configuration.getAPI({ url: 'language/list' }).then((data) => {
      if (data.status === 200) {
        setLanguage(data.payload)
        let titleArr = []
        data.payload.map((singleLang) => {
          console.log(singleLang)
          titleArr.push({ id: 0, title: `Title [${singleLang.title}] `, name: `${singleLang.code}`, type: "TextInput" })
        })
        setFieldArr1(titleArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);


  function handleChange(field, e) {
    console.log(field)
    setFields({ ...fields, [field]: e.target.value });
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      let status = fields.status
      let category_id = fields.category_id
      let created_at = fields.created_at
      let updated_at = fields.updated_at
      delete fields.title
      delete fields.status
      delete fields.category_id
      delete fields.created_at
      delete fields.updated_at
      let final = {
        title: fields,
        status: status,
        category_id: category_id,
        created_at: created_at,
        updated_at: updated_at,

     }
    configuration
      .postAPI({ url: "category/update", params: final })
      .then((data) => {
        if (data.status === 200) {
          history.push("/category");
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editCategory} />
      <AddEditSection
        title="Edit Category"
        path="/category"
        fields={[...fieldArr1, ...fieldArr]}
        fieldError={errors}
        language={language}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
