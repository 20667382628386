import PropTypes from "prop-types";
import React from "react";

// Add Edit Caption
function AddEditCaption({ title }) {
  return (
    <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
      <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
          {title}
        </h2>
      </div>
    </div>
  );
}

AddEditCaption.propTypes = {
  title: PropTypes.string,
};

AddEditCaption.defaultProps = {
  title: "",
};

export default AddEditCaption;
