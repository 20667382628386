import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import AddEditCaption from "./AddEditCaption";
import FieldSection from "./FieldSection";
import ButtonSection from "./ButtonSection";
// import { WithContext as ReactTags } from 'react-tag-input';
import ReactTags from 'react-tag-autocomplete'

function AddEditSection({ title, fields, fieldValue, fieldError, path, handleChange, handleSubmit, is_multiple_file_upload, getRootProps, getInputProps, galleryArr, is_react_tags, reactTagObj, removeGallery, cancleImage, loader }) {

  return (
    <div className="py-6 sm:px-6">
      <div className="relative">
        <section className="px-4" aria-labelledby="contact-heading">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <AddEditCaption title={title} />
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                  <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="">
                        <div className="pt-1">
                          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                            {fields.map((field) => {
                              return (
                                <FieldSection
                                  key={field.id}
                                  type={field.type}
                                  title={field.title}
                                  name={field.name}
                                  value={fieldValue[field.name] || ''}
                                  options={field.options || []}
                                  handleChange={handleChange}
                                  fieldError={fieldError[field.name]}
                                  is_read_only={field.is_read_only ? true : false}
                                  is_file={field.is_file ? true : false}
                                  is_preview={field.is_preview ? true : false}
                                  is_full_screen={field.is_full_screen ? true : false}
                                  is_number={field.is_number ? true : false}
                                  maxLength={12}
                                  is_password={field.is_password || false}
                                  defaultChecked={(field.type === "Radio") ? fieldValue[field.name] : ''}
                                  cancleImage={cancleImage}
                                />
                              );
                            })}
                            {/* Hash tags code start */}
                            {
                              (is_react_tags) ?
                                <>
                                  <div className="sm:col-span-2">
                                    <label className={`block text-base text_theme font-medium text-gray-700`}>Hash Tag</label>
                                    <div className="mt-1">
                                      <ReactTags
                                        // ref={tagRef}
                                        tags={reactTagObj.tags}
                                        suggestions={reactTagObj.suggestions}
                                        onDelete={(e) => reactTagObj.onDelete(e)}
                                        onAddition={(e) => reactTagObj.onAddition(e)}
                                        allowNew={true}
                                        newTagText="Create new tag: "
                                      />
                                      {/* <ReactTags
                                        tags={reactTagObj.tags}
                                        delimiters={reactTagObj.delimiters}
                                        handleDelete={reactTagObj.handleDelete}
                                        handleAddition={reactTagObj.handleAddition}
                                        handleDrag={reactTagObj.handleDrag}
                                        handleTagClick={reactTagObj.handleTagClick}
                                        inputFieldPosition="bottom"
                                        onClearAll={reactTagObj.onClearAll}
                                        // suggestions={[{ "id": "1", "text": "Albania" }, { "id": "2", "text": "Australia" }, { "id": "3", "text": "France" }, { "id": "4", "text": "India" }, { "id": "5", "text": "Oman" }, { "id": "6", "text": "Russia" }, { "id": "7", "text": "Serbia" }, { "id": "8", "text": "Swaziland" }, { "id": "9", "text": "United States of America" }, { "id": "10", "text": "Vietnam" }]}
                                        placeholder="Write tag name and press enter or comma"
                                        // minQueryLength={2}
                                        // maxLength={5}
                                        // autofocus={false}
                                        // allowDeleteFromEmptyInput={true}
                                        // autocomplete={true}
                                        // readOnly={false}
                                        // allowUnique={true}
                                        // allowDragDrop={true}
                                        // inline={true}
                                        // allowAdditionFromPaste={true}
                                        // editable={true}
                                        clearAll={true}
                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
                                      /> */}
                                    </div>
                                  </div>
                                </>
                                : ''
                            }
                          </div>
                        </div>
                        {/* Multiple file uploads code start */}
                        {
                          (is_multiple_file_upload) ?
                            <>
                              <label className={`block text-base text_theme font-medium text-gray-700 mt-5 mb-2`}>Upload Picture of News</label>


                              <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                  <input {...getInputProps()} />
                                  <p>Drag 'n' drop some files here, or click to select files</p>
                                </div>
                              </section>
                              <span id="loader" style={{ display: (loader) ? "block" : "none" }}>Please wait...</span>

                              <div className="grid grid-cols-4 gap-4">
                                {galleryArr.map((image, index) => (
                                  <div className="mt-5">
                                    <div key={index} className="image-item text-center px-3 py-3 border border-gray-200">
                                      {(image.type === "video") ?
                                        <video controls="controls" src={image.url} type="video/mp4" style={{ maxHeight: '185px', width: '100%' }} /> :
                                        <img className="mx-auto" src={image.url} alt="" width="100%" style={{ maxHeight: '185px', }} />
                                      }
                                      <div className="image-item__btn-wrapper">
                                        <button
                                          className="inline-flex justify-center items-center px-3 py-1 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 mr-3"
                                          onClick={() => removeGallery(image.fileName)}
                                          type="button">
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                            : ''}
                      </div>
                      <ButtonSection path={path} handleSubmit={handleSubmit} />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

AddEditSection.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.func,
  // "fields.map": PropTypes.instanceOf(Array),
  path: PropTypes.string,
};

AddEditSection.defaultProps = {
  title: "",
  fields: () => {
    // do nothing.
  },
  // "fields.map": [],
  path: "",
};

export default AddEditSection;
