import Footer from "../parts/Footer";
import Header from "../parts/Header";
import PropTypes from "prop-types";
import React from "react";

// For Login User Layout
function Template({ children }) {
  return (
    <div className="min-h-screen bg-gray-100 page_bg">
      <Header />
      {children}
      <Footer />
    </div>
  );
}

Template.propTypes = {
  children: PropTypes.string,
};
Template.defaultProps = {
  children: "",
};

export default Template;
