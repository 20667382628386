import React, { useState, useEffect, useRef } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import ListSection from "../../components/ListSection";
import DeleteModel from "../../components/DeleteModel";
import labels from "../../constants/Label";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { pluck } from 'underscore';

// List all labels
function View(location) {
  const columns = [
    {
      title: "ID",
      field: "id",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={`/label/edit/${rowData.label_id}`}
        >
          {rowData.label_id}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    { title: "Code", field: "label_code" },
  ];
  const btnGroups = [
    { id: 1, title: "Add New", type: "addNew", path: "/label/add" },
    { id: 2, title: "Export CSV", type: "exportCSV", path: "/label" },
    { id: 3, title: "Import CSV", type: "importCSV", path: "/label" },
    { id: 5, title: "Delete", type: "delete", path: "/label" },
  ];

  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  let inputFile = useRef(null) 

  useEffect(() => {
    if (location.state && location.state.is_added) {
      setShow(true)
      setTimeout(
        () => setShow(false),
        3000
      );
    }
    getDatas();
  }, [location.state]);

  function getDatas() {
    configuration.getAPI({ url: 'language-label/list', params: {} }).then((data) => {
      if (data.status === 200) {
        setData(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  function handleAction(type) {
    if(type === 'importCSV'){
      console.log('Open File upload')
      inputFile.click();
    } else {
      if (ids.length < 1) {
        return toast.error('Please select at least one record')
      } else if(type === 'delete'){
        setShowModal(true);
        return;
      }
      const sendData = {
        ids,
        type: (type === 'makeActive') ? 'active' : (type === 'makeInactive') ? 'inactive' : type
      }
      configuration.postAPI({ url: 'language-label/action', params: sendData }).then((data) => {
        if (data.status === 200) {
          getDatas()
          setIds([]);
          return toast.success((type === 'delete') ? 'Record deleted successfully' : 'Record update successfully')
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleDelete() {
    const sendData={
      ids,
      type:'delete'
    }
    configuration.postAPI({url:'language-label/action', params:sendData}).then((data) => {
      if(data.status === 200){
        getDatas()
        setIds([]);
        setShowModal(false);
        return toast.success('Record deleted successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleCancel() {
    setShowModal(false);
  }
  function handleImportCsv(e){
    const formData = new FormData();
    formData.append('csv', e.target.files[0]);
    formData.append('fields', JSON.stringify({lang_code:'EN'}));
    configuration.postFormDataAPI({url:'language-label/import-csv', params:formData}).then((data) => {
      inputFile.value = '';
      // console.log(data)
      if(data.status === 200){
        getDatas()
        setIds([]);
        return toast.success("Successfully import")
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }

  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'label_id');
    setIds(idArr)
  }


  return (
    <>
      <Breadcrumb module={labels.label} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <DeleteModel 
        mode={showModal}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <input className="profile" id="myInput" type="file" ref={(ref) => inputFile = ref} onChange={handleImportCsv} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{ display: 'none' }} />
      <ListSection
        captionTitle="View Label"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
        handleAction={handleAction}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
}

export default View;
