import React from "react";

// For Table Checkbox
function ColumnRow() {
  return (
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <input
        id="comments"
        aria-describedby="comments-description"
        name="comments"
        type="checkbox"
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
    </td>
  );
}

export default ColumnRow;
