import { BrowserRouter as Router, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import {reactLocalStorage} from 'reactjs-localstorage';
import configuration from './config';
import { toast } from 'react-toastify';

// Add Components
import AddCity from "./pages/City/Add";
import AddCms from "./pages/CMS/Add";
import AddCountry from "./pages/Country/Add";
import AddCurrency from "./pages/Currency/Add";
import AddEmailTemplate from "./pages/EmailTemplate/Add";
import AddHelp from "./pages/Help/Add";
import AddHelpCategory from "./pages/HelpCategory/Add";
import AddLabel from "./pages/Label/Add";
import AddLanguage from "./pages/Language/Add";
import AddCategory from "./pages/Category/Add";
import AddServices from "./pages/Services/Add";
import AddPushTemplate from "./pages/PushTemplate/Add";
import AddRole from "./pages/Role/Add";
import AddSmsTemplate from "./pages/SmsTemplate/Add";
import AddState from "./pages/State/Add";
import AddTax from "./pages/Tax/Add";
import AddUsers from "./pages/User/Add";
import Profile from "./pages/User/Profile";
import AddZipcode from "./pages/Zipcode/Add";
import AddAdminUsers from "./pages/AdminUser/Add";

// Edit Components
import EditCity from "./pages/City/Edit";
import EditCms from "./pages/CMS/Edit";
import EditCountry from "./pages/Country/Edit";
import EditCurrency from "./pages/Currency/Edit";
import EditEmailTemplate from "./pages/EmailTemplate/Edit";
import EditHelp from "./pages/Help/Edit";
import EditHelpCategory from "./pages/HelpCategory/Edit";
import EditLabel from "./pages/Label/Edit";
import EditLanguage from "./pages/Language/Edit";
import EditCategory from "./pages/Category/Edit";
import EditServices from "./pages/Services/Edit";
import EditPushTemplate from "./pages/PushTemplate/Edit";
import EditRole from "./pages/Role/Edit";
import EditSmsTemplate from "./pages/SmsTemplate/Edit";
import EditState from "./pages/State/Edit";
import EditTax from "./pages/Tax/Edit";
import EditUsers from "./pages/User/Edit";
import EditZipcode from "./pages/Zipcode/Edit";
import EditAdminUsers from "./pages/AdminUser/Edit";

// List Components
import City from "./pages/City/View";
import Cms from "./pages/CMS/View";
import Country from "./pages/Country/View";
import Currency from "./pages/Currency/View";
import Dashboard from "./pages/Dashboard/View";
import Sales from "./pages/Sales/View";
import Orders from "./pages/Orders/View";
import EmailTemplate from "./pages/EmailTemplate/View";
import ForgotPassword from "./pages/ForgotPassword/View";
import GuestRoute from "./layout/GuestRoute";
import Help from "./pages/Help/View";
import HelpCategory from "./pages/HelpCategory/View";
import Label from "./pages/Label/View";
import Language from "./pages/Language/View";
import Category from "./pages/Category/View";
import Services from "./pages/Services/View";
import LoginLog from "./pages/LoginLog/View";
import DataBackup from "./pages/DataBackup/View";
import Permission from "./pages/AccessRight/View";
import PersonalSettings from "./pages/Setting/View";
import PushTemplate from "./pages/PushTemplate/View";
import Role from "./pages/Role/View";
import Setting from "./pages/Setting/Menu";
import SignIn from "./pages/SignIn/View";
import OTP from "./pages/OTP/View";
import Reset from "./pages/Reset/View";
import SmsTemplate from "./pages/SmsTemplate/View";
import State from "./pages/State/View";
import Tax from "./pages/Tax/View";
import TemplateRoute from "./layout/TemplateRoute";
import Users from "./pages/User/View";
import Zipcode from "./pages/Zipcode/View";
import PageNotFound from "./pages/404/View";
import UserProfile from "./pages/UserProfile/View";
import UserProfileLink from "./pages/UserProfile/UserProfileLink";
import AdminUser from "./pages/AdminUser/View";

import NotificationLog from "./pages/Notification/log";
import NotificationSend from "./pages/Notification/send";

// Manage Routes
function App() {
  useEffect(() => {
    configuration.getAPI({url:'common/get-settings', params:{}}).then((data) => {
      if(data.status === 200){
        document.title = data.payload.app_name
        const dark_mode = document.getElementById('style-direction');
        dark_mode.href = (reactLocalStorage.get('dark_mode') === 'yes')?'/css/tailwind-dark.css':'/css/tailwind-light.css';

        const css_name = document.getElementById('style-color');
        css_name.href = `/css/${(data.payload.admin_color)?data.payload.admin_color:data.payload.admin_color}.css`;
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  return (
    <main>
      <Router>
        <Switch>
          <GuestRoute exact path="/" component={SignIn} />
          <GuestRoute exact path="/user-profile/:id" component={UserProfile} />
          <GuestRoute exact path="/profile/share/:id" component={UserProfileLink} />
          <GuestRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <GuestRoute exact path="/otp" component={OTP} />
          <GuestRoute exact path="/reset" component={Reset} />
          <TemplateRoute exact path="/dashboard" component={Dashboard} />
          <TemplateRoute exact path="/sales" component={Sales} />
          <TemplateRoute exact path="/orders" component={Orders} />
          <TemplateRoute exact path="/setting" component={Setting} />
          <TemplateRoute
            exact
            path="/personal-settings"
            component={PersonalSettings}
          />
          <TemplateRoute exact path="/role" component={Role} />
          <TemplateRoute exact path="/role/add" component={AddRole} />
          <TemplateRoute exact path="/role/edit/:id" component={EditRole} />
          <TemplateRoute exact path="/language" component={Language} />
          <TemplateRoute exact path="/language/add" component={AddLanguage} />
          <TemplateRoute
            exact
            path="/language/edit/:id"
            component={EditLanguage}
          />
          <TemplateRoute exact path="/category" component={Category} />
          <TemplateRoute exact path="/category/add" component={AddCategory} />
          <TemplateRoute
            exact
            path="/category/edit/:id"
            component={EditCategory}
          />
          <TemplateRoute exact path="/services" component={Services} />
          <TemplateRoute exact path="/services/add" component={AddServices} />
          <TemplateRoute
            exact
            path="/services/edit/:id"
            component={EditServices}
          />
          <TemplateRoute exact path="/label" component={Label} />
          <TemplateRoute exact path="/label/add" component={AddLabel} />
          <TemplateRoute exact path="/label/edit/:id" component={EditLabel} />
          <TemplateRoute exact path="/tax" component={Tax} />
          <TemplateRoute exact path="/tax/add" component={AddTax} />
          <TemplateRoute exact path="/tax/edit/:id" component={EditTax} />
          <TemplateRoute exact path="/country" component={Country} />
          <TemplateRoute exact path="/country/add" component={AddCountry} />
          <TemplateRoute
            exact
            path="/country/edit/:id"
            component={EditCountry}
          />
          <TemplateRoute exact path="/state" component={State} />
          <TemplateRoute exact path="/state/add" component={AddState} />
          <TemplateRoute exact path="/state/edit/:id" component={EditState} />
          <TemplateRoute exact path="/city" component={City} />
          <TemplateRoute exact path="/city/add" component={AddCity} />
          <TemplateRoute exact path="/city/edit/:id" component={EditCity} />
          <TemplateRoute exact path="/zipcode" component={Zipcode} />
          <TemplateRoute exact path="/zipcode/add" component={AddZipcode} />
          <TemplateRoute
            exact
            path="/zipcode/edit/:id"
            component={EditZipcode}
          />
          <TemplateRoute exact path="/help-category" component={HelpCategory} />
          <TemplateRoute
            exact
            path="/help-category/add"
            component={AddHelpCategory}
          />
          <TemplateRoute
            exact
            path="/help-category/edit/:id"
            component={EditHelpCategory}
          />
          <TemplateRoute exact path="/help" component={Help} />
          <TemplateRoute exact path="/help/add" component={AddHelp} />
          <TemplateRoute exact path="/help/edit/:id" component={EditHelp} />
          <TemplateRoute exact path="/cms" component={Cms} />
          <TemplateRoute exact path="/cms/add" component={AddCms} />
          <TemplateRoute exact path="/cms/edit/:id" component={EditCms} />
          <TemplateRoute exact path="/currency" component={Currency} />
          <TemplateRoute exact path="/currency/add" component={AddCurrency} />
          <TemplateRoute
            exact
            path="/currency/edit/:id"
            component={EditCurrency}
          />
          <TemplateRoute
            exact
            path="/email-template"
            component={EmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/add"
            component={AddEmailTemplate}
          />
          <TemplateRoute
            exact
            path="/email-template/edit/:id"
            component={EditEmailTemplate}
          />
          <TemplateRoute exact path="/push-template" component={PushTemplate} />
          <TemplateRoute
            exact
            path="/push-template/add"
            component={AddPushTemplate}
          />
          <TemplateRoute
            exact
            path="/push-template/edit/:id"
            component={EditPushTemplate}
          />
          <TemplateRoute exact path="/sms-template" component={SmsTemplate} />
          <TemplateRoute
            exact
            path="/sms-template/add"
            component={AddSmsTemplate}
          />
          <TemplateRoute
            exact
            path="/sms-template/edit/:id"
            component={EditSmsTemplate}
          />
          <TemplateRoute exact path="/user" component={Users} />
          <TemplateRoute exact path="/user/profile/:user_id" component={Profile} />
          <TemplateRoute exact path="/user/add" component={AddUsers} />
          <TemplateRoute exact path="/user/edit/:id" component={EditUsers} />
          <TemplateRoute exact path="/log" component={LoginLog} />
          <TemplateRoute exact path="/data-backup" component={DataBackup} />
          <TemplateRoute exact path="/permission" component={Permission} />
          
          <TemplateRoute exact path="/admin-user" component={AdminUser} />
          <TemplateRoute exact path="/admin-user/add" component={AddAdminUsers} />
          <TemplateRoute exact path="/admin-user/edit/:id" component={EditAdminUsers} />

          <TemplateRoute exact path="/notification-log" component={NotificationLog} />
          <TemplateRoute exact path="/notification/send" component={NotificationSend} />

          <GuestRoute component={PageNotFound} />
        </Switch>
      </Router>
    </main>
  );
}

export default App;
