import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import configuration from '../../config';
import { toast } from 'react-toastify';

function View({ history }) {
  const [userId, setUserId] = useState({});
  const [data, setData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  useEffect(() => {
    getDatas();
  }, []);
  let length1 = history.location.pathname.split('/').length;
  length1 = (length1-1)
  let userProfileLink = history.location.pathname.split('/')[length1];

  function getDatas() {
    configuration
      .getAPI({ url: "user/profile-link-web-view", params: { profile_link: userProfileLink } })
      .then((data) => {
        if (data.status === 200) {
          configuration.getAPI({ url: "user/check-direct-service", params: { user_id: data.payload.userInfo.user_id } })
            .then((dataService) => {
              if (dataService.status === 200) {
                if (dataService.payload) {
                  setUserId(data.payload.userInfo.user_id);
                  window.location.href = dataService.payload
                  return;
                } else {
                  setData(data.payload.userInfo);
                  setServiceData(data.payload.userServices);
                }
              }
            })
            .catch((error) => {
              return toast.error(error.message);
            });
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function handleCount(webLink) {
    let user_id = {
      user_id: this.state.userId
    };
    configuration.postAPI({ url: 'user/increase-link-taps', params: user_id }).then((data) => {
      if (data.status === 200) {
        window.location.href = webLink;
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }

  return (
    <main>
      <div
        style={{ textAlign: 'center', backgroundImage: `url(${(data.profile_type == 'personal') ? (data.personal_data) ? data.personal_data.cover_image_url : '' : (data.business_data) ? (data.business_data.cover_image_url) : ''})`, position: 'relative', backgroundSize: 'cover', height: '200px', }}>
        <div style={{ paddingTop: '150px' }}>
          <img style={{ width: '100px', height: '100px', margin: '0 auto', borderRadius: '50%', border: '4px solid #ffffff' }}
            alt="" src={(data.profile_type == 'personal') ? (data.personal_data) ? data.personal_data.profile_image_url : '' : (data.business_data) ? (data.business_data.profile_image_url) : ''} />
          <h2 style={{ fontWeight: '500', fontSize: '20px', textAlign: 'center', padding: '0', margin: '0' }}>{(data.profile_type == 'personal') ? (data.personal_data) ? data.personal_data.name : (data.name) : (data.business_data) ? (data.business_data.name) : (data.name)}
          </h2>
          <h2 style={{ fontWeight: '400', fontSize: '14px', textAlign: 'center', padding: '0', margin: '0' }}>{(data.profile_type == 'personal') ? (data.personal_data) ? data.personal_data.bio : (data.bio) : (data.business_data) ? (data.business_data.bio) : (data.bio)}
          </h2>
          <h2 style={{ fontWeight: '400', fontSize: '14px', textAlign: 'center', padding: '0', margin: '0' }}>{(data.profile_type == 'personal') ? (data.personal_data) ? data.personal_data.location : (data.location) : (data.business_data) ? (data.business_data.location) : (data.location)}
          </h2>
        </div>
      </div>
      <div style={{ background: '#ffffff', padding: '130px 20px 30px' }}>
        <p style={{ fontSize: '18px', textAlign: 'center', fontWeight: '500' }}>{data.active_lang_code == 'ES'? "Bienvenida a mi tarjeta de visita digital" : "Welcome to my digital Business Card"}</p>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <a style={{ width: '80%', background: '#000000', borderRadius: '60px', padding: '13px 0', display: 'inline-block', color: '#ffffff', textDecoration: 'none', textAlign: 'center', }}
            href="#">{data.active_lang_code == 'ES'? "Agregar a contacto" : "Add to Contact"}</a>
          <a style={{ width: '20%', display: 'inline-block', textAlign: 'center', paddingTop: '3px', }} href="#"><img
            style={{ width: '30px', margin: '0 auto', }} alt="" src="../image/add-user.png" /></a>
        </div>
        <div style={{ padding: '30px 0 0 0', gridTemplateColumns: 'repeat(3,minmax(0,1fr))', columnGap: '1.5rem', rowGap: '1.5rem', display: 'grid', }}>
          {
            (serviceData.length > 0) ?
              serviceData.map((service) => {
                return (<a onClick={() => handleCount(service.link)}><img style={{ width: '100%', }} alt="" src={service.icon_url} /></a>)
              })
              : data.active_lang_code == 'ES'? "La usuario no ha añadido ningún enlace." : "User has not added any links"
          }
        </div>
      </div>
    </main>
  );
}

View.propTypes = {
  history: PropTypes.func,
};

View.defaultProps = {
  history: () => {
  },
};

export default View;
