import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/City";

// For Add City
function Add({ history }) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  const [countryOpt, setCurrencyOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const fieldArr = [
    {
      id: 0,
      title: "Country",
      name: "country_id",
      type: "Dropdown",
      options: countryOpt,
    },
    {
      id: 1,
      title: "State",
      name: "state_id",
      type: "Dropdown",
      options: stateOpt,
    },
    { id: 2, title: "Title", name: "name", type: "TextInput" },
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    name: "",
    country_id: "",
    state_id: "",
    status: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.name) {
      error['name'] = "Please enter city title"
      flag = false
    }

    if (!fields.country_id) {
      error['country_id'] = "Please select country"
      flag = false
    }

    if (!fields.state_id) {
      error['state_id'] = "Please select state"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  function getCountryDatas() {
    configuration
      .getAPI({ url: "country/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.country_id;
              element.id = element.country_id;

              return element;
            });
          }
          setCurrencyOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getStateDatas(params) {
    configuration
      .getAPI({ url: "state/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.state_id;
              element.id = element.state_id;

              return element;
            });
          }
          setStateOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  useEffect(() => {
    getCountryDatas();
    // getStateDatas();
  }, []);

  function handleChange(field, e) {
    if (field === "country_id" && !e.target.value) {
      setStateOpt([]);
    }
    if (field === "country_id" && e.target.value) {
      getStateDatas({ country_id: e.target.value });
      setFields({ ...fields, ["state_id"]: '', [field]: e.target.value });
    } else {
      setFields({ ...fields, [field]: e.target.value });
    }
  }
  // function handleChange(field, e) {
  //   setFields({ ...fields, [field]: e.target.value });
  // }

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "city/create", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/city");
            // setData(data.payload)
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.addCity} />
      <AddEditSection
        title="Add City"
        path="/city"
        fields={fieldArr}
        fieldError={errors}

        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
