import PropTypes from "prop-types";
import React from "react";

// For List Heading
function Caption({ title }) {
  return (
    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
        {title}
      </h2>
    </div>
  );
}

Caption.propTypes = {
  title: PropTypes.string,
};

Caption.defaultProps = {
  title: "",
};

export default Caption;
