import * as fs from 'fs';
import { CogIcon, UserCircleIcon, OfficeBuildingIcon, TranslateIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { Switch, RadioGroup } from '@headlessui/react'
import Breadcrumb from "../../parts/Breadcrumb";
import _ from "underscore";
import labels from "../../constants/Setting";
import common from "../../constants/Common";
import Label from "../../components/Form/Label";
import { Link } from "react-router-dom";
import {reactLocalStorage} from 'reactjs-localstorage';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { findWhere, union } from 'underscore';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const product = {
  colors: [
    { name: 'Black', bgColor: 'bg-gray-900', selectedColor: 'ring-gray-900', css_name: 'black' },
    { name: 'Red', bgColor: 'bg-red-700', selectedColor: 'ring-red-700', css_name: 'red' },
    { name: 'Orange', bgColor: 'bg-orange-600', selectedColor: 'ring-orange-600', css_name: 'orange' },
    { name: 'Green', bgColor: 'bg-green-900', selectedColor: 'ring-green-900', css_name: 'green' },
    { name: 'Blue', bgColor: 'bg-blue-900', selectedColor: 'ring-blue-900', css_name: 'blue' },
    { name: 'Purple', bgColor: 'bg-purple-900', selectedColor: 'ring-purple-900', css_name: 'purple' },
    { name: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500', css_name: 'pink' },
  ],
}

const product1 = {
  colors: [
    { name: 'BlackLight', bgColor: 'bg-gray-400', selectedColor: 'ring-gray-400', css_name: 'gray' },
    { name: 'RedLight', bgColor: 'bg-red-400', selectedColor: 'ring-red-400', css_name: 'red-light' },
    { name: 'OrangeLight', bgColor: 'bg-orange-400', selectedColor: 'ring-orange-400', css_name: 'orange-light' },
    { name: 'YellowLight', bgColor: 'bg-yellow-400', selectedColor: 'ring-yellow-400', css_name: 'yellow' },
    { name: 'BlueLight', bgColor: 'bg-blue-400', selectedColor: 'ring-blue-400', css_name: 'blue-light' },
    { name: 'PurpleLight', bgColor: 'bg-purple-400', selectedColor: 'ring-purple-400', css_name: 'purple-light' },
    { name: 'GreenLight', bgColor: 'bg-green-300', selectedColor: 'ring-green-400', css_name: 'green-light' },
  ],
}
// List Settings
function View() {
  const [subNavigation, setSubNavigation] = useState([
    { name: "General", href: "#", icon: UserCircleIcon, current: true },
    { name: "Company Info", href: "#", icon: OfficeBuildingIcon, current: false },
    { name: "Appearance", href: "#", icon: CogIcon, current: false },
    // { name: "Language & Region", href: "#", icon: TranslateIcon, current: false },
  ]);
  const [tab, setTab] = useState(0)
  const [logo, setLogo] = useState(null)
  const [favicon, setFavicon] = useState(null)
  const [fields, setFields] = useState({})
  const [country, setCountry] = useState([]);
  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [errors, setErrors] = useState(0)
  function setTabMenu(index) {
    setTab(index)
    const navigate = subNavigation;
    _.each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  const [enabled, setEnabled] = useState(false)
  const [selectedColor, setSelectedColor] = useState(product.colors[0])
  useEffect(() => {
    const dark_mode = reactLocalStorage.get('dark_mode');
    setEnabled((dark_mode==='yes')?true:false);
    configuration.getAPI({url:'common/get-settings', params:{}}).then((data) => {
      if(data.status === 200){
        setFields({...data.payload, logo:common.logo_url, favicon:common.favicon_url})
        document.title = data.payload.app_name
        const colorData = findWhere(union(product.colors, product1.colors), {css_name:data.payload.admin_color})
        console.log(colorData)
        if(colorData){
          setSelectedColor(colorData)
        }
      }
    }).catch(error => {
      return toast.error(error.message)
    });
    configuration.getAPI({ url: "country/list", params: {status:'active'} }).then((data) => {
      setCountry(data.payload)
    }).catch((error) => {
      return toast.error(error.message);
    });
    configuration.getAPI({ url: "language/list", params: {status:'active'} }).then((data) => {
      setLanguage(data.payload)
    }).catch((error) => {
      return toast.error(error.message);
    });
    configuration.getAPI({ url: "currency/list", params: {status:'active'} }).then((data) => {
      setCurrency(data.payload)
    }).catch((error) => {
      return toast.error(error.message);
    });
  }, []);
  function onChangeSwitch(e, type){
    console.log(type);
    if(type === 'dark_mode'){
      const style = document.getElementById('style-direction');
      style.href = (e)?'/css/tailwind-dark.css':'/css/tailwind-light.css';
      reactLocalStorage.set('dark_mode', (e)?"yes":"no");
      setEnabled(e)
    } else {
      setFields({ ...fields, [type]: e })
    }
  }
  function onChangeColor(e){
    reactLocalStorage.set('css_name', e.css_name);
    setSelectedColor(e)
    setFields({ ...fields, ['admin_color']: e.css_name })
  }
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.time_zone) {
      error['time_zone'] = "Please select time zone"
      flag = false
    }
    if (!fields.app_name) {
      error['app_name'] = "Please enter Platform name"
      flag = false
    }
    if (!fields.default_country_id) {
      error['default_country_id'] = "Please select default country"
      flag = false
    }
    if (!fields.default_country_id) {
      error['default_country_id'] = "Please select default country"
      flag = false
    }

    if (!fields.copy_right_text) {
      error['copy_right_text'] = "Please Enter copy right text"
      flag = false
    }
    if (!fields.company_website) {
      error['company_website'] = "Please Enter company website"
      flag = false
    }
    if (!fields.company_address) {
      error['company_address'] = "Please enter company address"
      flag = false
    }
    if (!fields.company_phone_number) {
      error['company_phone_number'] = "Please enter company phone number"
      flag = false
    }
    if (!fields.support_email) {
      error['support_email'] = "Please enter support email"
      flag = false
    }
    if (!fields.support_phone_number) {
      error['support_phone_number'] = "Please enter support phone number"
      flag = false
    }
    setErrors({...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (validation()) {
      const formData = new FormData();
      if(logo){
        formData.append('logo', logo);
      }
      if(favicon){
        formData.append('favicon', favicon);
      }
      delete fields.logo;
      delete fields.favicon;
      // console.log("fields");
      // console.log(fields);
      formData.append('fields', JSON.stringify({...fields, lang_code:'EN'}));
      configuration.postFormDataAPI({url:'common/update-settings', params:formData}).then((data) => {
        // console.log(data)
        if(data.status === 200){
          const style = document.getElementById('style-color');
          style.href = `/css/${fields.admin_color}.css`;
          return toast.success('Setting Updated Successfully')
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  function cancleImage(field, e) {
    if(field == 'favicon'){
      setFavicon(null);
    } else {
      setLogo(null);
    }
    setFields({ ...fields, [field]: '' })
  }
  function handlePhoto(field, e) {
    console.log(field)
    if(field == 'favicon'){
      setFavicon(e.target.files[0]);
    } else {
      setLogo(e.target.files[0]);
    }
    setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
  }
  return (
    <>
      <Breadcrumb module={labels.personalSetting} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Personal Settings
                      </h2>
                    </div>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                    <main className="relative">
                      <div className="mx-auto">
                        <div className="bg-white rounded-lg shadow overflow-hidden box_bg">
                          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-2">
                              <nav className="space-y-1">
                                {subNavigation.map((item, index) => (
                                  <Link
                                    to="#"
                                    key={item.name}
                                    onClick={() => setTabMenu(index)}
                                    onKeyDown={() => setTabMenu(index)}
                                    className={classNames(
                                      item.current
                                        ? "bg-indigo-50 text-indigo-700 text-teal-700 hover:bg-teal-50 hover:text-teal-700 border-indigo-600 input_theme"
                                        : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                      "group border-l-4 px-3 py-2 flex items-center text-base xl-admin-btn"
                                    )}
                                    aria-current={item.current ? "page" : null}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-teal-500 group-hover:text-teal-500"
                                          : "text-gray-400 group-hover:text-gray-500",
                                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="truncate">
                                      {item.name}
                                    </span>
                                  </Link>
                                ))}
                              </nav>
                            </aside>
                            {tab === 0 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">General</h2>
                                <p className="mt-1 text-sm text-gray-500 text_theme">
                                  This information will be displayed publicly so be careful what you share.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-8">
                                    <label htmlFor="app_name" className="block text-sm font-medium text-gray-700 text_theme">
                                      Platform Name
                                    </label>
                                    <input
                                      type="text"
                                      name="app_name"
                                      id="app_name"
                                      value = {fields.app_name}
                                      onChange={(e)=>handleChange('app_name', e)}
                                      autoComplete="given-name"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.app_name ? <Label title='app_name' fieldError={errors.app_name} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-8">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-sm font-medium text-gray-700 text_theme">
                                        Notification
                                      </label>
                                      <Switch
                                        checked={fields.is_backend_notification}
                                        onChange={(e)=>onChangeSwitch(e, 'is_backend_notification')}
                                        className={classNames(
                                          fields.is_backend_notification ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            fields.is_backend_notification ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      Default Country
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.default_country_id}
                                      onChange={(e)=>handleChange('default_country_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      {country.map((data,key)=>{
                                        return <option value={data.country_id} key={key}>{data.name}</option>
                                      })}
                                    </select>
                                    {errors.default_country_id ? <Label title='default_country_id' fieldError={errors.default_country_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      App Default Language
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.app_language_id}
                                      onChange={(e)=>handleChange('app_language_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      {language.map((data,key)=>{
                                        return <option value={data.language_id} key={key}>{data.title}</option>
                                      })}
                                    </select>
                                    {errors.app_language_id ? <Label title='app_language_id' fieldError={errors.app_language_id} /> : null}
                                  </div>
                                  
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      Default Currency
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.default_currency_id}
                                      onChange={(e)=>handleChange('default_currency_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      {currency.map((data,key)=>{
                                        return <option value={data.currency_id} key={key}>{data.name}</option>
                                      })}
                                    </select>
                                    {errors.default_currency_id ? <Label title='default_currency_id' fieldError={errors.default_currency_id} /> : null}
                                  </div>
                                  
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="copy_right_text" className="block text-sm font-medium text-gray-700 text_theme">
                                      Copy Right Text
                                    </label>
                                    <input
                                      type="text"
                                      name="copy_right_text"
                                      id="copy_right_text"
                                      value = {fields.copy_right_text}
                                      onChange={(e)=>handleChange('copy_right_text', e)}
                                      autoComplete="copy_right_text"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                                  </div>

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e)=>handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 1 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Company Info</h2>
                                <p className="mt-1 text-sm text-gray-500 text_theme">
                                  Enter your company information.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 text_theme">
                                      Company Website
                                    </label>
                                    <input
                                      type="text"
                                      name="company_website"
                                      id="company_website"
                                      value = {fields.company_website}
                                      onChange={(e)=>handleChange('company_website', e)}
                                      autoComplete="company_website"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.company_website ? <Label title='company_website' fieldError={errors.company_website} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 text_theme">
                                      Company Address
                                    </label>
                                    <input
                                      type="text"
                                      name="company_address"
                                      id="company_address"
                                      value = {fields.company_address}
                                      onChange={(e)=>handleChange('company_address', e)}
                                      autoComplete="company_address"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.company_address ? <Label title='company_address' fieldError={errors.company_address} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 text_theme">
                                      Company Phone
                                    </label>
                                    <input
                                      type="number"
                                      name="company_phone_number"
                                      id="company_phone_number"
                                      value = {fields.company_phone_number}
                                      onChange={(e)=>handleChange('company_phone_number', e)}
                                      autoComplete="company_phone_number"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.company_phone_number ? <Label title='company_phone_number' fieldError={errors.company_phone_number} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      Support Email
                                    </label>
                                    <input
                                      type="text"
                                      name="support_email"
                                      id="support_email"
                                      value = {fields.support_email}
                                      onChange={(e)=>handleChange('support_email', e)}
                                      autoComplete="support_email"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.support_email ? <Label title='support_email' fieldError={errors.support_email} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      Support Phone
                                    </label>
                                    <input
                                      type="number"
                                      name="support_phone_number"
                                      id="support_phone_number"
                                      value = {fields.support_phone_number}
                                      onChange={(e)=>handleChange('support_phone_number', e)}
                                      autoComplete="support_phone_number"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md input_theme"
                                    />
                                    {errors.support_phone_number ? <Label title='support_phone_number' fieldError={errors.support_phone_number} /> : null}
                                  </div>

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e)=>handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 2 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Appearance</h2>
                                <p className="mt-1 text-sm text-gray-500 text_theme">
                                  Choose appearance of backoffice.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-8">
                                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-900 pb-4 text_theme">
                                      Choose Theme
                                    </label>
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                      <h3 className="text-sm font-medium text-gray-700 text_theme">
                                        Dark Layout
                                      </h3>
                                      <div className="mt-2 mb-4">
                                        <form>
                                          <div>
                                            <RadioGroup value={selectedColor} onChange={(e)=>onChangeColor(e)} className="mt-2">
                                              <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                                              <div className="flex items-center space-x-3">
                                                {product.colors.map((color) => (
                                                  <RadioGroup.Option
                                                    key={color.name}
                                                    value={color}
                                                    className={({ active, checked }) =>
                                                      classNames(
                                                        color.selectedColor,
                                                        active && checked ? 'ring ring-offset-1' : '',
                                                        !active && checked ? 'ring-2' : '',
                                                        '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                                                      )
                                                    }
                                                  >
                                                    <RadioGroup.Label as="p" className="sr-only">
                                                      {color.name}
                                                    </RadioGroup.Label>
                                                    <span
                                                      aria-hidden="true"
                                                      className={classNames(
                                                        color.bgColor,
                                                        'h-8 w-8 border border-black border-opacity-10 rounded-full'
                                                      )}
                                                    />
                                                  </RadioGroup.Option>
                                                ))}
                                              </div>
                                            </RadioGroup>
                                          </div>
                                        </form>
                                      </div>
                                      <h3 className="text-sm font-medium text-gray-700 text_theme">
                                        Light Layout
                                      </h3>
                                      <div className="mt-2">
                                        <form>
                                          <div>
                                            <RadioGroup value={selectedColor} onChange={(e)=>onChangeColor(e)} className="mt-2">
                                              <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                                              <div className="flex items-center space-x-3">
                                                {product1.colors.map((color) => (
                                                  <RadioGroup.Option
                                                    key={color.name}
                                                    value={color}
                                                    className={({ active, checked }) =>
                                                      classNames(
                                                        color.selectedColor,
                                                        active && checked ? 'ring ring-offset-1' : '',
                                                        !active && checked ? 'ring-2' : '',
                                                        '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                                                      )
                                                    }
                                                  >
                                                    <RadioGroup.Label as="p" className="sr-only">
                                                      {color.name}
                                                    </RadioGroup.Label>
                                                    <span
                                                      aria-hidden="true"
                                                      className={classNames(
                                                        color.bgColor,
                                                        'h-8 w-8 border border-black border-opacity-10 rounded-full'
                                                      )}
                                                    />
                                                  </RadioGroup.Option>
                                                ))}
                                              </div>
                                            </RadioGroup>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-sm font-medium text-gray-900 text_theme">
                                        Dark Mode
                                      </label>
                                      <Switch
                                        checked={enabled}
                                        onChange={(e)=>onChangeSwitch(e, 'dark_mode')}
                                        className={classNames(
                                          enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 text_theme">
                                      Font
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.font_family}
                                      onChange={(e)=>handleChange('font_family', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      <option value="Arial">Arial</option>
                                      <option value="Helvetica">Helvetica</option>
                                      <option value="Verdana">Verdana</option>
                                      <option value="serif">serif</option>
                                      <option value="FreeMono">FreeMono</option>
                                      <option value="Futara">Futara</option>
                                      <option value="Geneva">Geneva</option>
                                    </select>
                                    {errors.font_family ? <Label title='font_family' fieldError={errors.font_family} /> : null}
                                  </div>

                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 text_theme">
                                      Upload Logo 
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      {(fields.logo && fields.logo !== '')?
                                        <div className="space-y-1">
                                          <img name="logo" id="logo" src={fields.logo} width="100" height="100"/>
                                          <button 
                                            type="button" 
                                            onClick={(e)=>cancleImage('logo', e)}
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                          > Remove</button>
                                        </div>:
                                        <div className="space-y-1 text-center">
                                        <svg
                                          className="mx-auto h-12 w-12 text-gray-400"
                                          stroke="currentColor"
                                          fill="none"
                                          viewBox="0 0 48 48"
                                          aria-hidden="true"
                                        >
                                          <path
                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                          <label
                                            htmlFor="file-upload"
                                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                          >
                                            <span>Upload a file</span>
                                            <input id="file-upload" name="file-upload" type="file" onChange={(e)=>handlePhoto('logo', e)} accept="image/*" className="sr-only" />
                                          </label>
                                          <p className="pl-1 text_theme">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
                                      </div>
                                      }
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      Upload Favicon
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      {(fields.favicon && fields.favicon !== '')?
                                        <div className="space-y-1">
                                          <img name="favicon" id="favicon" src={fields.favicon} width="100" height="100"/>
                                          <button 
                                            type="button" 
                                            onClick={(e)=>cancleImage('favicon', e)}
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                          > Remove</button>
                                        </div>
                                        :<div className="space-y-1 text-center">
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                          <div className="flex text-sm text-gray-600">
                                            <label
                                              htmlFor="file-favicon"
                                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                              <span>Upload a file</span>
                                              <input id="file-favicon" name="file-favicon" type="file" onChange={(e)=>handlePhoto('favicon', e)} accept="image/*" className="sr-only" />
                                            </label>
                                            <p className="pl-1 text_theme">or drag and drop</p>
                                          </div>
                                          <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                      }
                                    </div>
                                  </div> */}
                                    
                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                     {/*  <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e)=>handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 3 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Language & Region</h2>
                                <p className="mt-1 text-sm text-gray-500 text_theme">
                                  This information will be displayed publicly so be careful what you share.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 text_theme">
                                      TimeZone
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.time_zone}
                                      onChange={(e)=>handleChange('time_zone', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                      <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                      <option value="-10:00">(GMT -10:00) Hawaii</option>
                                      <option value="-09:50">(GMT -9:30) Taiohae</option>
                                      <option value="-09:00">(GMT -9:00) Alaska</option>
                                      <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                      <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                      <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                      <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                      <option value="-04:50">(GMT -4:30) Caracas</option>
                                      <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                      <option value="-03:50">(GMT -3:30) Newfoundland</option>
                                      <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                      <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                      <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                      <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                      <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                      <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                      <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                      <option value="+03:50">(GMT +3:30) Tehran</option>
                                      <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                      <option value="+04:50">(GMT +4:30) Kabul</option>
                                      <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                      <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                      <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                                      <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                      <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                                      <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                      <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                      <option value="+08:75">(GMT +8:45) Eucla</option>
                                      <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                      <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                                      <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                      <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                                      <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                      <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                                      <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                      <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                                      <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                      <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                    </select>
                                    {errors.time_zone ? <Label title='time_zone' fieldError={errors.time_zone} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-sm font-medium text-gray-700 text_theme">
                                      Language
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.default_language_id}
                                      onChange={(e)=>handleChange('default_language_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      {language.map((data,key)=>{
                                        return <option value={data.language_id} key={key}>{data.title}</option>
                                      })}
                                    </select>
                                    {errors.default_language_id ? <Label title='default_language_id' fieldError={errors.default_language_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-sm font-medium text-gray-700 text_theme">
                                      Date Format
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value = {fields.date_format}
                                      onChange={(e)=>handleChange('date_format', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm input_theme"
                                    >
                                      <option value="DD-MM-YYYY">DD-MM-YYYY [01-08-2019]</option>
                                      <option value="DD-MMM-YYYY">DD-MMM-YYYY [01-Aug-2019]</option>
                                      <option value="DD-MMM-YY">DD-MMM-YY [01-Aug-19]</option>
                                      <option value="YYYY-MM-DD">YYYY-MM-DD [2019-08-01]</option>
                                      <option value="MM-DD-YY">MM-DD-YY [08-01-19]</option>
                                      <option value="MMMM Do, YYYY">MMMM Do, YYYY [August 1st, 2019]</option>
                                      <option value="M/D/YYYY">M/D/YYYY [8/1/2019]</option>
                                    </select>
                                    {errors.date_format ? <Label title='date_format' fieldError={errors.date_format} /> : null}
                                  </div>
                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e)=>handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default View;
