import React, { useState } from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import tableIcons from './miui-icons'

// Table
function DataTable({ columns, data, onSelectionChange, tableMsg }) {
  const [selectedRow] = useState(null);

  let pageSizeOptions = []
  addPush(5)
  addPush(10)
  addPush(20)
  addPush(50)
  addPush(100)
  addPush(200)
  addPush(500)
  addPush(1000)
  function addPush(number) {
    pageSizeOptions.push(number)
    // if (data.length > number) {
    // }
  }
  // pageSizeOptions.push(data.length)
  return (
    <MaterialTable
      icons={tableIcons}
      columns={columns}
      data={data}
      title={false}
      pagination
      paginationMode="server"
      localization={{
        body: {
          emptyDataSourceMessage: <div><img className='norecord' src="/image/no-record.png" alt="no-record"/><h1>There are not any {tableMsg} yet.</h1></div>
        }
      }}
      options={{
        search: true,
        selection: true,
        sorting: true,
        emptyRowsWhenPaging: false,
        pageSizeOptions:pageSizeOptions,
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
      }}
      onSelectionChange={onSelectionChange}
    />
  );
}

DataTable.propTypes = {
  data: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  onSelectionChange: PropTypes.func,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  onSelectionChange: () => {
    // do nothing.
  },
};

export default DataTable;
