import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import { reactLocalStorage } from 'reactjs-localstorage';
import labels from "../../constants/Category";
import _ from 'underscore'
// For Add Language
function Add({ history }) {
  const statusOpt = [
    // 
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
    { id: 3, title: "Archive", value: "archive" },
  ];
  // const [fieldArr, setFieldArr] = useState([
  //   { id: 0, title: "Title", name: "title", type: "TextInput" },
  //   {
  //     id: 1,
  //     title: "Status",
  //     name: "status",
  //     type: "Dropdown",
  //     options: statusOpt,
  //   },
  // ]);

  let fieldArr = [
    {
      id: 1,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },
  ];

  const [fieldArr1, setFieldArr] = useState([]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    code: "",
  });
  const [language, setLanguage] = useState([])

  function handleValidation() {
    let flag = true;
    let error = {}

    language.forEach((single) => {
      if (!fields[single.code]) {
        error[single.code] = "Please enter category title in " + single.title
        flag = false
      }
    })

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    // console.log(error)
    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () => {
   
    if (handleValidation()) {
    console.log(fields)
    const status = fields.status
     delete fields.status
    let title = fields 
    let final = {
      title: title,
      status: status
     }
      configuration
        .postAPI({ url: "category/create", params: final })
        .then((data) => {
          if (data.status === 200) {
            history.push("/category");
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error)
          return toast.error(error.message);
        });
    }
  };
  console.log("fields")
  console.log(fields)
  function handleChange(field, e) {
    console.log("field", field)
    setFields({ ...fields, [field]: e.target.value });
  }
  useEffect(() => {
   let apiTokenData = reactLocalStorage.get('token');
    let user_id = reactLocalStorage.get('user_id');
    console.log(apiTokenData, user_id)
    let value = {
      status: 'active'
    }

    configuration.getAPI({ url: 'language/list' }).then((data) => {
      if (data.status === 200) {
        setLanguage(data.payload)
        let titleArr = []
        data.payload.map((singleLang) => {
          titleArr.push({ id: singleLang.language_id, title: `Title [${singleLang.title}]`, name: `${singleLang.code}`, type: "TextInput" })
        })
        setFieldArr(titleArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, [])
  return (
    <>
      <Breadcrumb module={labels.addCategory} />

      <AddEditSection
        title="Add Category"
        path="/category"
        fields={[...fieldArr1, ...fieldArr]}
        fieldError={errors}
        fieldValue={fields}
        language={language}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
