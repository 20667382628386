import Breadcrumb from "../../parts/Breadcrumb";
import React from "react";
import labels from "../../constants/Sales";

// For Sales
function View() {
  return <Breadcrumb module={labels.sales} />;
}

export default View;
