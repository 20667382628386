import React, { useState, useEffect } from "react";
import labels from "../constants/Footer";
import configuration from '../config';
import { toast } from 'react-toastify';

// For Footer
function Footer() {
  const [fields, setFields] = useState({})
  useEffect(() => {
    configuration.getAPI({url:'common/get-settings', params:{}}).then((data) => {
      if(data.status === 200){
        setFields(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  return (
    <footer className="w-full px-4 sm:px-6 lg:px-8">
      <div className="py-12 text-center md:flex md:justify-between">
        <p className="text-base text-gray-400">
          &copy; {fields.copy_right_text}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
