import { HomeIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import labels from "../constants/Breadcrumb";

// For Navigate
function Breadcrumb({ module }) {
  let breadcrumbArr = [];
  switch (module) {
    case "Dashboard":
      breadcrumbArr = [{ name: "Dashboard", href: "#", current: true }];
      break;
    case "Sales":
      breadcrumbArr = [{ name: "Sales", href: "#", current: true }];
      break;
    case "Orders":
      breadcrumbArr = [{ name: "Orders", href: "#", current: true }];
      break;
    case "Setting":
      breadcrumbArr = [
        { name: "Adminland", href: "#", current: true },
      ];
      break;
    case "PersonalSetting":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Settings", href: "#", current: true },
      ];
      break;
    case "Role":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Role", href: "#", current: true },
      ];
      break;
    case "AddRole":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Role", href: "/role", current: false },
        { name: "Add Role", href: "#", current: true },
      ];
      break;
    case "EditRole":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Role", href: "/role", current: false },
        { name: "Edit Role", href: "#", current: true },
      ];
      break;
    case "Language":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Language", href: "#", current: true },
      ];
      break;
    case "AddLanguage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Language", href: "/language", current: false },
        { name: "Add Language", href: "#", current: true },
      ];
      break;
    case "EditLanguage":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Language", href: "/language", current: false },
        { name: "Edit Language", href: "#", current: true },
      ];
      break;
    case "Category":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Category", href: "#", current: true },
      ];
      break;
    case "AddCategory":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Category", href: "/category", current: false },
        { name: "Add Category", href: "#", current: true },
      ];
      break;
    case "EditCategory":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Category", href: "/category", current: false },
        { name: "Edit Category", href: "#", current: true },
      ];
      break;
    case "Services":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Services", href: "#", current: true },
      ];
      break;
    case "AddServices":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Services", href: "/Services", current: false },
        { name: "Add Services", href: "#", current: true },
      ];
      break;
    case "EditServices":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "Services", href: "/Services", current: false },
        { name: "Edit Services", href: "#", current: true },
      ];
      break;
    case "Label":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Label", href: "#", current: true },
      ];
      break;
    case "AddLabel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Label", href: "/label", current: false },
        { name: "Add Label", href: "#", current: true },
      ];
      break;
    case "EditLabel":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Label", href: "/label", current: false },
        { name: "Edit Label", href: "#", current: true },
      ];
      break;
    case "Tax":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax", href: "#", current: true },
      ];
      break;
    case "AddTax":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax", href: "/tax", current: false },
        { name: "Add Tax", href: "#", current: true },
      ];
      break;
    case "EditTax":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Tax", href: "/tax", current: false },
        { name: "Edit Tax", href: "#", current: true },
      ];
      break;
    case "Country":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Country", href: "#", current: true },
      ];
      break;
    case "AddCountry":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Country", href: "/country", current: false },
        { name: "Add Country", href: "#", current: true },
      ];
      break;
    case "EditCountry":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Country", href: "/country", current: false },
        { name: "Edit Country", href: "#", current: true },
      ];
      break;
    case "State":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "State", href: "#", current: true },
      ];
      break;
    case "AddState":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "State", href: "/state", current: false },
        { name: "Add State", href: "#", current: true },
      ];
      break;
    case "EditState":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "State", href: "/state", current: false },
        { name: "Edit State", href: "#", current: true },
      ];
      break;
    case "City":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "City", href: "#", current: true },
      ];
      break;
    case "AddCity":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "City", href: "/city", current: false },
        { name: "Add City", href: "#", current: true },
      ];
      break;
    case "EditCity":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "City", href: "/city", current: false },
        { name: "Edit City", href: "#", current: true },
      ];
      break;
    case "Zipcode":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Zipcode", href: "#", current: true },
      ];
      break;
    case "AddZipcode":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Zipcode", href: "/city", current: false },
        { name: "Add Zipcode", href: "#", current: true },
      ];
      break;
    case "EditZipcode":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Zipcode", href: "/city", current: false },
        { name: "Edit Zipcode", href: "#", current: true },
      ];
      break;
    case "HelpCategory":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help Category", href: "#", current: true },
      ];
      break;
    case "AddHelpCategory":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help Category", href: "/help-category", current: false },
        { name: "Add Help Category", href: "#", current: true },
      ];
      break;
    case "EditHelpCategory":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help Category", href: "/help-category", current: false },
        { name: "Edit Help Category", href: "#", current: true },
      ];
      break;
    case "Help":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help", href: "#", current: true },
      ];
      break;
    case "AddHelp":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help", href: "/help", current: false },
        { name: "Add Help", href: "#", current: true },
      ];
      break;
    case "EditHelp":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Help", href: "/help", current: false },
        { name: "Edit Help", href: "#", current: true },
      ];
      break;
    case "CMS":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "CMS", href: "#", current: true },
      ];
      break;
    case "AddCMS":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "CMS", href: "/cms", current: false },
        { name: "Add CMS", href: "#", current: true },
      ];
      break;
    case "EditCMS":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "CMS", href: "/cms", current: false },
        { name: "Edit CMS", href: "#", current: true },
      ];
      break;
    case "Currency":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Currency", href: "#", current: true },
      ];
      break;
    case "AddCurrency":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Currency", href: "/currency", current: false },
        { name: "Add Currency", href: "#", current: true },
      ];
      break;
    case "EditCurrency":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Currency", href: "/currency", current: false },
        { name: "Edit Currency", href: "#", current: true },
      ];
      break;
    case "EmailTemplates":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Email Template", href: "#", current: true },
      ];
      break;
    case "AddEmailTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Email Template", href: "/email-template", current: false },
        { name: "Add Email Template", href: "#", current: true },
      ];
      break;
    case "EditEmailTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Email Template", href: "/email-template", current: false },
        { name: "Edit Email Template", href: "#", current: true },
      ];
      break;
    case "PushTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Push Templates", href: "#", current: true },
      ];
      break;
    case "AddPushTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Push Template", href: "/push-template", current: false },
        { name: "Add Push Template", href: "#", current: true },
      ];
      break;
    case "EditPushTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Push Template", href: "/push-template", current: false },
        { name: "Edit Push Template", href: "#", current: true },
      ];
      break;
    case "SmsTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "SMS Template", href: "#", current: true },
      ];
      break;
    case "AddSmsTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Sms Template", href: "/sms-template", current: false },
        { name: "Add Push Template", href: "#", current: true },
      ];
      break;
    case "EditSmsTemplate":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Sms Template", href: "/sms-template", current: false },
        { name: "Edit Push Template", href: "#", current: true },
      ];
      break;
    case "Users":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "User", href: "#", current: true },
      ];
      break;
    case "AddUsers":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "User", href: "/user", current: false },
        { name: "Add User", href: "#", current: true },
      ];
      break;
    case "Profile":
      breadcrumbArr = [
        { name: "Dashboard", href: "/dashboard", current: false },
        { name: "User", href: "/user", current: false },
        { name: "Profile", href: "#", current: true },
      ];
      break;
    case "EditUsers":
      breadcrumbArr = [
        { name: "dashboard", href: "/Dashboard", current: false },
        { name: "User", href: "/user", current: false },
        { name: "Edit User", href: "#", current: true },
      ];
      break;
    case "LoginLogs":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Login Log", href: "#", current: true },
      ];
      break;
    default:
    case "DataBackup":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Database Backup", href: "#", current: true },
      ];
      break;
    case "Permission":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Access Right", href: "#", current: true },
      ];
      break;

    case "AdminUser":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "AdminUser", href: "#", current: true },
      ];
      break;
    case "AddAdminUser":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "AdminUser", href: "/admin-user", current: false },
        { name: "Add AdminUser", href: "#", current: true },
      ];
      break;
    case "EditAdminUser":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "AdminUser", href: "/admin-user", current: false },
        { name: "Edit AdminUser", href: "#", current: true },
      ];
      break;
    case "NotificationLog":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Notification Log", href: "#", current: true },
      ];
      break;
    case "SendNotification":
      breadcrumbArr = [
        { name: "Adminland", href: "/setting", current: false },
        { name: "Notification", href: "/notification-log", current: true },
        { name: "Send Notification", href: "#", current: true },
      ];
      break;
  }

  return (
    <nav
      className="bg-white border-b border-gray-200 flex xl-breadcrumb breadcrumb_theme"
      aria-label="Breadcrumb"
    >
      <ol
        // eslint-disable-next-line jsx-a11y/aria-role
        role="listData"
        className="max-w-screen-xl w-full px-4 flex space-x-4 sm:px-6 lg:px-8"
      >
        <li className="flex">
          <div className="flex items-center">
            <Link
              to="/dashboard"
              className="text-gray-700 hover:text-indigo-600 icon_theme"
            >
              <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{labels.home}</span>
            </Link>
          </div>
        </li>
        {breadcrumbArr.map((page) => (
          <li key={page.name} className="flex">
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 w-6 h-full text-gray-200 breadcrumb_separator"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              {page.current ? (
                <span
                  className="ml-4 text-base font-medium text-gray-400"
                  aria-current={page.current ? "page" : null}
                >
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-base font-medium text-gray-700 hover:text-indigo-600 icon_theme"
                  aria-current={page.current ? "page" : null}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

Breadcrumb.propTypes = {
  module: PropTypes.string,
};

Breadcrumb.defaultProps = {
  module: "",
};

export default Breadcrumb;
