import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";

import labels from "../../constants/Services";
import _ from 'underscore'
import { lang } from 'moment';
// For Edit Language

let Id = "";
let user_id = "";

function Edit({ history }) {
  const [map, setMap] = useState([1])
  const [icon, set_icon] = useState('')
  const [typeofCategory, settypeofCategory] = useState([]);
  const [category_id, set_category_id] = useState("");
  const [language, setLanguage] = useState([])

  const [fields, setFields] = useState({
    title: "",
    description: "",
    personal: false,
    icon: "",
    business: false,
    labels: [],
    status: "",
    typeofCategory: "",
    category_id: "",
    user_id: "",

  });
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    personal: "",
    icon: "",
    business: '',
    labels: [],
    status: "",
    // labels: '',
    // typeofCategory: "",
    category_id: "",
  });
  function handleFieldChange(index, e) {
    let labels = fields.labels
    labels[index] = e.target.value
    setFields({ ...fields, ['labels']: labels });
  }
  // const addExtra = () => {
  //   let labels = fields.labels
  //   labels.push([])
  //   // labels.push({ title: "" })
  //   setFields({ ...fields, ['labels']: labels });
  // }
  const addExtra = () => {
    let labels = fields.labels
    let error = errors.labels
    // labels.push({ title: "" })
    setFields({ ...fields, ['labels']: [...labels, ''] });
    setErrors({ ...errors, ['labels']: [...error, ''] })
  }
  const removeExtra = (index) => {
    let labels = fields.labels
    labels = labels.filter((s, idx) => idx !== index)
    setFields({ ...fields, ['labels']: labels });
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    error['labels'] = errors.labels
    if (!fields.category_id) {
      error['category_id'] = "Please enter category"
      flag = false
    }
    language.forEach((single) => {
      if (!fields["title_" + single.code]) {
        error["title_" + single.code] = "Please enter title in " + single.title
        flag = false
      }
    })
    language.forEach((single) => {
      if (!fields["des_" + single.code]) {
        error["des_" + single.code] = "Please enter description in " + single.title
        flag = false
      }
    })
    // if (!fields.personal) {
    //     error['personal'] = "Please Select personal"
    //     flag = false
    // }
    // if (!fields.business) {
    //     error['business'] = "Please Select business"
    //     flag = false
    // }
    if (!fields.icon) {
      error['icon'] = "Please Select icon"
      flag = false
    }

    _.each(fields.labels, (lang, ind) => {
      if (!fields['labels'][ind]) {
        error['labels'][ind] = "Please enter labels "
        flag = false
      } else {
        error['labels'][ind] = ""
      }
    })
    // if (!fields.status) {
    //     error['status'] = "Please select status"
    //     flag = false
    // }
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    let apiTokenData = reactLocalStorage.get('token');
    let user_id = reactLocalStorage.get('user_id');
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "service/list", params: { service_id: Id } })
      .then((data) => {
        if (data.status === 200) {
          console.log(data.payload)
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });

    configuration
      .getAPI({ url: "category/list?status=active", params: {} })
      .then((data) => {
        if (data.status === 200) {
          settypeofCategory(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    
    let value = {
      status: 'active'
    }

    configuration.getAPI({ url: 'language/list' }).then((data) => {
      if (data.status === 200) {
        setLanguage(data.payload)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  const handleSubmit = () => {
    
    if (handleValidation()) {
      
      let title = {}
      let description = {}
      language.forEach((single) => {
        title[single.code] = fields["title_" + single.code]
        description[single.code] = fields["des_" + single.code]
      })
      fields.title = title
      fields.description = description
      const formData = new FormData();
      if (icon) {
        formData.append('icon', icon);
      }
      fields['user_id'] = user_id;
      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));

      configuration
        .postFormDataAPI({ url: "service/update", params: formData })
        .then((data) => {
          if (data.status === 200) {
            history.push("/services");
            // setData(data.payload)
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };


  function handleChange(field, e) {
    console.log(field)
    if (field === "icon") {
      if (e.target.files.length > 0) {
        set_icon(e.target.files[0]);
        // setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) });
      }
    }
    if (field === "personal") {
      setFields({ ...fields, personal: !fields.personal });
    } else if (field === "business") {

      setFields({ ...fields, business: !fields.business });
    }
    else {
      setFields({ ...fields, [field]: e.target.value });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.editServices} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Add Services
                      </h2>
                    </div>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="pt-1">
                            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                              <div className="col-span-2">
                                <p className="text-base font-medium text-gray-700" aria-hidden="true">
                                  Icon
                                </p>
                                <div className="mt-1">
                                  <div className="flex items-center">
                                    <div
                                      className="flex-shrink-0 inline-block rounded-full overflow-hidden h-12 w-12"
                                      aria-hidden="true"
                                    >
                                      <img className="rounded-full h-full w-full" src={fields.icon ? fields.icon : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYsTbWjuQm15MFQBNxXkihFDoccB-HhZLA-A&usqp=CAU"} alt="" />

                                    </div>
                                    <div className="ml-5 rounded-md shadow-sm">
                                      <div className="group relative border border-gray-300 rounded-md py-2 px-3 flex items-center justify-center hover:bg-gray-50 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-sky-500">
                                        <label
                                          htmlFor="mobile-user-photo"
                                          className="relative text-base leading-4 font-medium text-gray-700 pointer-events-none"
                                        >
                                          <span>Upload</span>
                                          <span className="sr-only"> user photo</span>
                                        </label>
                                        <input
                                          onChange={(e) =>
                                            handleChange("icon", e)
                                          }

                                          id="mobile-user-photo"
                                          name="user-photo"
                                          type="file"
                                          className="absolute w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
                                        />
                                      </div>
                                      <label
                                        htmlFor="password"
                                        className="block font-medium text-red-700 text-base"
                                      >
                                        {errors.icon}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>

                                <label htmlFor="Title" className="block text-base font-medium text-gray-700">
                                  Category
                                </label>

                                <div className="mt-1">
                                  <select
                                    id="country"
                                    name="country"
                                    value={fields.category_id}
                                    onChange={(e) =>
                                      handleChange("category_id", e)
                                    }
                                    autoComplete="none"
                                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md"
                                  >
                                    <option value="">
                                      ---Select category---
                                    </option>
                                    {typeofCategory.map((cat, key) => {
                                      return (
                                        <option
                                          key={key}
                                          value={cat.category_id}
                                        >
                                          {cat.title}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <label
                                    htmlFor="password"
                                    className="block font-medium text-red-700 text-base"
                                  >
                                    {errors.category_id}
                                  </label>
                                </div>
                              </div>
                              {
                                language?.map((lang) => {
                                  return (
                                    <>
                                      <div>
                                        <label htmlFor="Title" className="block text-base font-medium text-gray-700">
                                          Title {`[${lang.title}]`}
                                        </label>
                                        <div className="mt-1">
                                          <input
                                            type="text"
                                            value={fields["title_" + lang.code]}
                                            onChange={(e) => handleChange("title_" + lang.code, e)}
                                            name={"title_" + lang.code}
                                            id={"title_" + lang.code}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-base border-gray-300 rounded-md"
                                            placeholder="you@example.com"
                                          />
                                          <label
                                            htmlFor="password"
                                            className="block font-medium text-red-700 text-base"
                                          >
                                            {errors["title_" + lang.code]}

                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                           }
                              

                              <div>

                                <label htmlFor="Title" className="w-full text-base font-medium text-gray-700">
                                  Type
                                </label>
                                <fieldset className="flex gap-5 mt-3">
                                  <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        checked={fields.personal}
                                        onChange={(e) =>
                                          handleChange("personal", e)
                                        }
                                        value={fields.personal}

                                        id="comments"
                                        aria-describedby="comments-description"
                                        name="comments"
                                        type="checkbox"
                                        className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="ml-3 text-base">
                                      <label htmlFor="comments" className="font-medium text-gray-700">
                                        Personal
                                      </label>
                                      {/* <label
                                        htmlFor="password"
                                        className="block font-medium text-red-700 text-base"
                                      >
                                        {errors.personal}
                                      </label> */}
                                    </div>
                                  </div>
                                  <div className="relative flex items-start">
                                    <div className="flex items-center h-5">
                                      <input
                                        checked={fields.business}
                                        onChange={(e) =>
                                          handleChange("business", e)
                                        }
                                        value={fields.business}

                                        id="candidates"
                                        aria-describedby="candidates-description"
                                        name="candidates"
                                        type="checkbox"
                                        className="focus:ring-indigo-500 h-5 w-5 text-indigo-600 border-gray-300 rounded"
                                      />
                                    </div>
                                    <div className="ml-3 text-base">
                                      <label htmlFor="candidates" className="font-medium text-gray-700">
                                        Business
                                      </label>
                                      {/* <label
                                        htmlFor="password"
                                        className="block font-medium text-red-700 text-base"
                                      >
                                        {errors.business}
                                      </label> */}
                                    </div>
                                  </div>
                                </fieldset>
                              </div>

                              <div>
                                  <label htmlFor="Title" className="block text-base font-medium text-gray-700">
                                      Link
                                  </label>
                                  <div className="mt-1">
                                      <input
                                          type="text"
                                          value={fields.link}
                                          onChange={(e) => handleChange("link", e)}
                                          name="Link"
                                          id="Link"
                                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-base border-gray-300 rounded-md"
                                          placeholder=""
                                      />
                                      <label
                                          htmlFor="password"
                                          className="block font-medium text-red-700 text-base"
                                      >
                                          {errors.link}
                                      </label>
                                  </div>
                              </div>
                              {
                                language?.map((lang) => {
                                  return (
                                    <>
                                      <div className="col-span-2">
                                        <label htmlFor="Title" className="block text-base font-medium text-gray-700">
                                          Description {`[${lang.title}]`}
                                        </label>
                                        <div className="mt-1">
                                          <textarea
                                            value={fields["des_" + lang.code]}
                                            rows={4}
                                            onChange={(e) => handleChange("des_" + lang.code, e)}
                                            name={"des_" + lang.code}
                                            id={"des_" + lang.code}
                                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                            defaultValue={''}
                                          />
                                          <label
                                            htmlFor="password"
                                            className="block font-medium text-red-700 text-base"
                                          >
                                            {errors["des_" + lang.code]}
                                          </label>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                           }
                              

                            </div>
                            <div className="mt-5">
                              <label htmlFor="Title" className="block text-xl font-bold text-gray-900">
                                Fields For Services
                              </label>
                              <div className="" >
                                <button
                                  onClick={() => addExtra()}
                                  type="button"
                                  className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                >
                                  + Add Fields
                                </button>
                              </div>
                              <div className="mt-3" >
                                {fields.labels.map((singleMap, index) => {
                                  return (

                                    <div className="grid grid-cols-2 gap-4 border-b-2 border-gray-300 border-dashed mb-5 pb-5">
                                      <div className="">
                                        <label className="flex-none text-base text_theme font-medium text-700">Labels</label>
                                        <input
                                          onChange={(e) => handleFieldChange(index, e)}
                                          value={singleMap}
                                          type="text"
                                          name="last-name"
                                          id="last-name"
                                          autoComplete="family-name"
                                          className="mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme"
                                        />
                                        <label
                                          htmlFor="password"
                                          className="block font-medium text-red-700 text-base"
                                        >
                                          {errors?.labels?.[index]}
                                        </label>
                                      </div>

                                      {/* <div className="mt-10">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="inline h-6 w-6 cursor-pointer inline-block ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                                                            </div> */}
                                      <div className="" >
                                        <button
                                          onClick={() => removeExtra(index)}
                                          type="button"
                                          className="mt-8 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                        >
                                          -
                                        </button>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pt-5 border_color_theme">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                              onClick={() => history.push("/services")}

                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                              onClick={() => handleSubmit()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Edit;
