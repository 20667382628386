import PropTypes from "prop-types";
import React from "react";

// For Label Element
function Label({ title, fieldError }) {
  return (
    <label className={`block text-base text_theme font-medium text-${fieldError ? 'red' : 'gray'}-700`}>{fieldError ? fieldError : title}</label>
  );
}

Label.propTypes = {
  title: PropTypes.string,
};

Label.defaultProps = {
  title: "",
};

export default Label;
